<template>
  <div>
    <h3>视频库</h3>
    <div class="link"><div class="linkTwo"></div></div>
    <el-row class="content" :gutter="20">
      <el-col :span="8" v-for="(item, index) in videoLib" :key="index">
        <div class="video-wrap">
          <img
            class="pic"
            src="@/assets/images/play.png"
            alt=""
            width="50"
            @click="handleVideoPlay"
          />
          <video :src="newBaseurl + item.vedioPath">
            <!-- <source /> -->
          </video>
          <!-- <h5 class="title">{{ item.imageTitle }}</h5> -->
        </div>
      </el-col>
    </el-row>
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      videoLib: [],
      pageIndex: 1,
      pageSize: 12,
      total: 0,
    };
  },
  mounted() {
    this.lafVideoLib();
  },

  methods: {
    lafVideoLib() {
      this.$axios
        .post("/laf/laf/lafMedia/tableList.ajax", {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          condition: "mediaClassify:S_IN0||",
        })
        .then((response) => {
          this.videoLib = response.rows;
          this.total = response.totalRows;
        })
        .catch(function (error) {});
    },

    handlePageChange(index) {
      this.pageIndex = index;
      this.lafVideoLib();
    },

    // 视频播放
    handleVideoPlay(e) {
      // 目标对象  视频
      const target = e.target;
      //  点击播放后 让播放按钮隐藏
      target.style.display = "none";
      // 从该元素往上找  找到外层包装的盒子
      const videWrap = target.closest(".video-wrap");
      // 再通过外层包装的盒子  最终找到 里面的标题
      // const title = videWrap.querySelector(".title");
      // 然后隐藏 标题
      // title.style.display = "none";

      const video = videWrap.querySelector("video");
      video.controls = "controls";
      video.play();
      // 播放完之后   立马注册事件   让播放按钮和标题显示   播放按钮隐藏
      video.addEventListener("ended", function (e) {
        target.style.display = "block";
        // title.style.display = "block";
        video.controls = null;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.el-main {
  overflow: hidden;
}
h3 {
  font-size: 16px;
  margin-left: 15px;
}
.link {
  margin: 25px 0;
  border-bottom: 1px solid #999999;
}
.linkTwo {
  width: 80px;
  height: 2px;
  margin-bottom: -1px;
  background: #e60620;
}
.content {
  padding-left: 30px;
  padding-right: 30px;
}

.video-wrap {
  position: relative;
  width: 100%;
  height: 217px;
  margin-bottom: 15px;
  .pic {
    position: absolute;
    margin-top: 80px;
    margin-left: 95px;
    cursor: pointer;
    z-index: 99;
  }
}
.title {
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 40px;
  height: 40px;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

video {
  width: 100%;
  // height: 217px;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}

::v-deep.el-tabs__header {
  margin: 0;
}
.fenYe {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>