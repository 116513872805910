<template>
  <div>
    <page-bread />
    <div class="home-container">
      <div class="list">
        <el-menu :default-active="catagory" mode="horizontal" @select="handleSelect">
          <el-menu-item
            class="welfareNav"
            :index="item.tag"
            v-for="item in menuList"
            :key="item.path"
            >{{ item.name }}</el-menu-item
          >
        </el-menu>
      </div>
          
      <el-container>
        <el-aside width="292px">
          <page-aside>
            <el-menu :default-active="activeIndex">
              <el-menu-item
                class="projectNav"
                :index="item.pathName"
                v-for="item in projectList"
                :key="item.pathName"
                @click="handleSideMenuClick(item.path)"
                >{{ item.name }}</el-menu-item
              >
            </el-menu>
          </page-aside>
        </el-aside>
        <el-container>
          <el-header>
            <div class="mobile-projectList">
              <el-radio-group>
                <el-radio-button
                  @click.native="handleProjectSelect(item.path)"
                  :label="item.name"
                  v-for="item in projectList"
                  :key="item.name"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-header>
          <el-main>
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </div>
</template>

<script>
import pageAside from "@/components/pageAside.vue";

export default {
  name: "",
  components: {
    pageAside
  },
  data() {
    return {
      activeIndex: this.$route.name,
      catagory: '',
      menuList: [
        { path: "/welfareProject/S_IN0/projectIntroduce",
          tag: 'S_IN0',
          name: "法援案件项目" 
        },
        { path: "/welfareProject/S_IN1/projectIntroduce", 
          tag: 'S_IN1',
          name: "普法宣传项目" 
        },
        { path: "/welfareProject/S_IN2/projectIntroduce", 
          tag: 'S_IN2',
          name: "特色站点项目" 
        },
        { path: "/welfareProject/S_IN3/projectIntroduce",
          tag: 'S_IN3',
          name: "高校法援项目" 
        },
        { path: "/welfareProject/S_IN4/projectIntroduce", 
          tag: 'S_IN4',
          name: "培训奖励项目" 
        },
      ],
      projectList: []
    };
  },
  mounted() {
    this.catagory = this.$route.params.id
    // 动态设置项目介绍、项目动态、项目成果的路径
    this.projectList = [
      { path: `/welfareProject/${this.catagory}/projectIntroduce`, 
        tag: `${this.catagory}`,
        name: "项目介绍",
        pathName: 'projectIntroduce'
      },
      { path: `/welfareProject/${this.catagory}/projectDynamic/1/`,
        tag: `${this.catagory}`,
        name: "项目动态" ,
        pathName: 'projectDynamic'
      },
      { path: `/welfareProject/${this.catagory}/projectResult`, 
        tag: `${this.catagory}`,
        name: "项目成果",
        pathName: 'projectResult'
      },
    ]
  },
  methods: {
    handleSelect(e){
      console.log(e)
      this.$router.push(`/welfareProject/${e}/projectIntroduce`)
    },
    handleProjectSelect(e){
      this.$router.push(`${e}`)
    },
    handleSideMenuClick(path) {
      this.$router.push(path)
    }
  },
  watch: {
    "$route.name"(val) {
      this.activeIndex = val;
    },
    // 监听id动态设置路径
    '$route.params.id'(val){
      this.catagory = this.$route.params.id
      this.projectList = [
       { path: `/welfareProject/${this.catagory}/projectIntroduce`, 
        tag: `${this.catagory}`,
        name: "项目介绍",
        pathName: 'projectIntroduce'
      },
      { path: `/welfareProject/${this.catagory}/projectDynamic/1/`,
        tag: `${this.catagory}`,
        name: "项目动态" ,
        pathName: 'projectDynamic'
      },
      { path: `/welfareProject/${this.catagory}/projectResult`, 
        tag: `${this.catagory}`,
        name: "项目成果",
        pathName: 'projectResult'
      },
      ]
    }
  },
};
</script>

<style lang='scss' scoped>
.welfareNav {
  @include apply-small {
    margin-top: 10px;
  }
}
::v-deep {
  .el-menu.el-menu--horizontal {
    display: inline-block;
    border: 0;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      color: #333333;
      border: none;
      background-color: #fff;
      margin-right: 20px;
      &.is-active {
        border: none;
        background-color: $red;
        color: #fff;
      }
      @include apply-small {
        margin-left: 5px;
      }
    }
  }
  .el-header{
    margin-top: 25px;
    height: 60px !important;
    @include apply-big{
      display: none;
    }
  }
  .el-main{
    padding: 0;
    padding-left: 15px;
  }
}

.list{
  text-align: center;
}
::v-deep .el-aside{
  box-sizing: border-box;
  padding-top: 100px;
  border-right: 8px solid #f4f4f4;
}

.mobile-projectList{
  text-align: center;
  height: 60px;
  .single-projectList{
    outline: #ccc;
    line-height: 60px;
  }
}

::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  box-shadow: none;
}
::v-deep .el-radio-button__orig-radio:focus + .el-radio-button__inner {
  // background-color: #c81b38;
  background-color: #dc2725 !important;
  box-shadow: -1px 0px 9px 0px #222222 !important;
  border-color: #ccc !important;
  color: #fff !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: transparent;
  // background-color: blue !important;
  box-shadow: none !important;
  border-color: #ccc !important;
  color: #000;
}
::v-deep .el-radio-button__inner {
  width: 130px;
  height: 40px;
  line-height: 15px;
  font-size: 18px;
}
</style>