<template>
  <div class="home-container detail">
    <div class="content">
      <div class="content-all">
        <h2>{{ message.messageTitle }}</h2>
        <p class="date">发布时间: {{ message.messageDate }}</p>
      </div>
      <div class="content-data">
        <p v-html="message.messageData"></p>
        <div class="download" v-if="message.messageFile">
          <div>附件下载:</div>
          <div @click="handleDownLoad">{{ message.messageTitle }}</div>
          <!-- <a href="javascript:;" @click="handleDownLoad">{{
            reportObj.messageTitle
          }}</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFileStream } from "@/utils/tools";
// import { mapState } from "vuex";
export default {
  name: "reportDetail",
  data() {
    return {
      message: {},
      content: "",
      pageNo: 1,
      pageSize: 10,
      condition: "messageType:S_IN1||",
      total: 0,
    };
  },
  // computed: mapState({
  //   reportObj: (state) => state.reportDetail.reportObj,
  // }),
  created() {
    this.$axios({
      method: "post",
      url: "/laf/laf/lafPublicMessage/find.ajax",
        data: {
        pageNo:1,
        pkValue: this.$route.query.pkValue,
        _entityName: 'org.aweto.laf.entity.lafPublicMessage'
      },
    })
      .then((res) => {
        this.message = res.recorder;
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    async handleDownLoad() {
      const res1 = await this.$axios({
        method: "post",
        url: "/laf/laf/lafPublicMessage/tableList.ajax",
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          condition: this.condition,
          orderCondition: "desc",
          orderField: "messageDate",
          fileId: this.message.messageFile,
        },
      });
      // 根据fileUrl获取附件信息
      const res2 = await this.$axios({
        method: "post",
        url: "/laf/attach/attachInfo/attachList.ajax",
        data: {
          fileId: this.message.messageFile,
        },
      });
      const {
        localType,
        localAddress,
        username,
        password,
        filePath,
        saveName,
        fileName,
        fileType,
        id: infoNo
      } = res2[0];
      // 下载附件
      const res3 = await this.$axios({
        method: "post",
        url: "/laf/fileDownloadServlet",
        data: {
          localType,
          localAddress,
          username,
          password,
          filepath: filePath,
          saveName,
          fileName,
          fileType,
          pathType: "absolute",
          encode: "UTF-8",
          infoNo,
          viewMode:false
        },
        responseType: "blob",
      });
      downloadFileStream(res3, fileName);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  // width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  padding-bottom: 20px;
  border-top: none;
  .content-all {
    width: 100%;
    height: 23%;
    border: 1px solid #e5e5e5;
    border-top: 3px solid #cd242a;
    h2 {
      color: #cd242a;
      font-size: 25px;
      margin-top: 15px;
      padding-top: 10px;
      text-align: center;
    }
    .date {
      font-size: 14px;
      text-align: center;
      color: #bbb;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
  .content-data {
    text-align: left;
    font-size: 16px;
    width: 100%;
    min-height: 500px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    overflow-y: auto;
    padding: 20px 20px 20px 20px;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-all;
  }
  .content-data::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  .content-data::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e5e5e5;
  }
  .content-data::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #e5e5e5;
    border-radius: 0;
    background: #e5e5e5;
  }
  .footer {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #e5e5e5;
    .next {
      float: left;
      margin-left: 30px;
      font-size: 12px;
    }
    .last {
      float: right;
      margin-right: 30px;
      font-size: 12px;
    }
  }
}
.detail {
  margin-top: 40px;
  margin-bottom: 40px;
}
.detail > div:first-child {
  margin-bottom: 15px;
  text-align: right;
}
.detail img {
  width: 100%;
  height: 300px;
}

.download {
  margin-left: 20px;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.download :nth-child(2) {
  font-size: 14px;
  margin-top: 10px;
  color: #dc2725;
  cursor: pointer;
  margin-left: 32px;
  &:hover {
    text-decoration: underline;
  }
}
</style>