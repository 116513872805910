<template>
  <div class="home-container">
    <el-row>
      <el-col :span="21">
        <span class="searchKeyWord">关键字：</span>
        <el-input
          class="input"
          size="medium"
          placeholder=""
          v-model="keyword"
        ></el-input>
        <el-button size="medium" @click="handleSearch">查询</el-button>
      </el-col>
      <el-col :span="3"
        ><el-button size="medium" @click="handleReport"
          >新建申报</el-button
        ></el-col
      >
    </el-row>
    <!-- 表格 -->
    <el-table :data="reportList">
      <el-table-column prop="prjClassify" label="项目分类" min-width="180" />
      <el-table-column prop="prjName" label="项目名称" min-width="180" />
      <el-table-column prop="firstDtm" label="创建时间" min-width="180" />
      <!-- <el-table-column prop="prjOrg" label="负责人" min-width="180" /> -->
      <el-table-column prop="wfState" label="流程处理状态" min-width="180" />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "homeList",
  data() {
    return {
      reportList: [],
      keyword: "",
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getProjectList();
  },
  methods: {
    getProjectList() {
      this.$axios
        .post("/laf/laf/lafProject/ykProjectList.ajax", {
          pageSize: this.pageSize,
          pageNo: this.pageIndex,
          condition: this.keyword ? `prjName:@${this.keyword}||` : "|",
          _entityName: "org.aweto.laf.entity.LafProject",
        })
        .then((response) => {
          this.reportList = response.rows;
          this.total = response.totalRows;
        })
        .catch(function (error) {});
    },

    handleSearch() {
      this.getProjectList();
    },

    handleReport() {
      this.$router.push("/home/report");
    },

    handlePageChange(index) {
      this.pageIndex = index;
      this.getProjectList();
    },
  },
};
</script>

<style lang='scss' scoped>
.searchKeyWord {
  margin-left: 40px;
}
.input {
  width: 270px;
  margin-right: 20px;
}
.home-container {
  margin-top: 20px;
}
.el-table {
  ::v-deep thead {
    color: $red;
  }
  ::v-deep th.is-leaf {
    border-color: $red;
  }
}
.fenYe {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
</style>