export default {
    state: {
        classicHomeObj: {}
    },
    actions: {
      setClassicHomeDetail ({ commit }, params) {
        commit({type: 'updateDetail', params})
      }
    },
    mutations: {
      updateDetail (state, data) {
        state.classicHomeObj = data.params
      }
    }
  }
  