<template>
  <div>
    <div class="search">
      <span class="keyWord">关键字：</span>
      <el-input
        class="int"
        v-model="newKeyWord"
        placeholder="请输入关键字"
      ></el-input>
      <button class="btn" @click="handleNewSearch">查询</button>
    </div>

    <div class="loading" v-if="isLoading">
      <span>数据加载中...</span>
    </div>
    <div class="noMatch" v-if="showNoMatch">
      <span>没有搜索到匹配结果</span>
    </div>

    <div style="margin-top: 10px">
      <div
        v-for="(item, index) in JudicialContent"
        :key="index"
        @click="goDetail(item)"
        class="contentWrap"
      >
        <img
          class="img"
          :src="newBaseurl + item.newsPh"
          alt=""
          @error="handleNoImg"
        />
        <div class="content-detail">
          <h3 class="title">{{ item.newsTitle }}</h3>
          <div class="date">{{ item.pubDtm }}</div>
          <p class="text">{{ item.newsText }}</p>
        </div>
      </div>
      <el-pagination
        class="fenYe"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="pageIndex"
        @current-change="handlePageChange"
        @prev-click="handlePageChange"
        @next-click="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      JudicialContent: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      newKeyWord: "",
      showNoMatch: false,
      isLoading: false,
      condition: "",
    };
  },
  created() {
    this.pageIndex = parseInt(this.$route.params.page) || 1;
  },
  mounted() {
    this.getJudicial();
  },
  watch: {
    "$route.params.page"(val) {
      this.pageIndex = val;
      this.getJudicial();
    },
  },
  methods: {
    getJudicial() {
      this.isLoading = true;
      this.$axios({
        method: "post",
        url: "/laf/laf/lafNews/tableList.ajax",
        data: {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          _entityName: "org.aweto.laf.entity.LafNews",
          condition: `newsTitle:@${this.newKeyWord}|newsClas:S_IN02||`,
          orderCondition: "desc",
          orderField: "pubDtm",
        },
      })
        .then((res) => {
          this.JudicialContent = res.rows;
          this.total = res.totalRows;
          this.isLoading = false;
          if (this.JudicialContent.length) {
            this.showNoMatch = false;
          } else {
            this.showNoMatch = true;
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    },

    // 详情
    goDetail(data) {
      // const { row_pkValue } = data;
      const row_pkValue = data.row_pkValue;
      window.open(`./#/news/detail?row_pkValue=${row_pkValue}`, "_blank");

      // this.$router.push({
      //   path: "/news/detail",
      //   query: {
      //     row_pkValue: row_pkValue,
      //   },
      // });
    },
    handlePageChange(index) {
      this.$router.push({
        name: "judicial",
        params: {
          page: index,
        },
      });
    },

    // 搜索查询接口
    handleNewSearch() {
      // const loading = this.$loading({
      //   background: "rgba(255,255,255, 0.7)",
      // });
      this.pageIndex = 1;
      this.showNoMatch = false;
      this.condition = this.newKeyWord.toString();
      this.getJudicial();
    },
  },
};
</script>

<style lang='scss' scoped>
.search {
  display: flex;
  margin-top: 12px;
  margin-bottom: 17px;
  .int {
    margin-right: 12px;
    border: 0;
  }
  .btn {
    width: 90px;
    height: 35px;
    background-color: #cd242a;
    border-radius: 2px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    outline: none;
  }
  .keyWord {
    display: block;
    margin-right: 30px;
    line-height: 35px;
    margin-left: 24px;
    font-size: 14px;
    color: #333333;
    font-weight: 700;
  }
}

.noMatch {
  padding-top: 30px;
  text-align: center;
  color: #999;
  font-size: 20px;
}
.loading {
  padding-top: 30px;
  text-align: center;
  color: #999;
  font-size: 20px;
}
::v-deep .el-tabs__content {
  overflow: visible;
}
::v-deep.el-input {
  width: 270px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}
::v-deep .el-input__inner {
  height: 35px;
}
::v-deep .el-menu-item {
  font-size: 16px;
  color: #cd242a;
}
::v-deep .el-input__inner:focus {
  border-color: #e5e5e5;
}
.contentWrap {
  padding: 20px;
  text-decoration: none;
  //  margin-bottom:20px;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
  height: 200px;
  &:hover {
    background: #f8f8f8;
  }

  .img {
    display: block;
    width: 180px;
    height: 150px;
    float: left;
    object-fit: cover;
  }
  .content-detail {
    margin-left: 180px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .title {
    font-size: 20px;
    margin-bottom: 12px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px;
    line-height: 30px;
  }
  .date {
    color: #999;
    margin-bottom: 25px;
    font-size: 14px;
  }
  .text {
    font-size: 16px;
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}

::v-deep.el-tabs__header {
  margin: 0;
}
.fenYe {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>