
<template>
  <div>
    <img class="image" src="@/assets/images/star3.png" alt="" />
    <img class="image1" src="@/assets/images/star2.jpg" alt="" />
    <div class="home-container">
      <div class="caseLine"></div>
      <div
        class="details"
        v-for="(item, index) in indexList"
        :key="index"
        @click="toCaseDetail(item)"
      >
        <div class="title">
          {{ item.title }}
        </div>
        <div class="caseDate">{{ item.publishTime }}</div>
      </div>
      <!-- 分页 -->
      <el-pagination
        class="fenYe"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="pageIndex"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      indexList: [],
      pageIndex: 1,
      pageSize: 12,
      total: 0,
    };
  },
  mounted() {
    this.lafCase();
  },
  methods: {
    // 典型案例列表
    lafCase() {
      this.$axios
        .post("/laf/laf/lafTypicalCase/tableList.ajax", {
          pageSize: this.pageSize,
          pageNo: this.pageIndex,
          condition: "-1",
          orderCondition: "desc",
          orderField: "publishTime",
        })
        .then((response) => {
          this.indexList = response.rows;
          this.total = response.totalRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 点击跳转到详情页
    toCaseDetail(data) {
      const row_pkValue = data.row_pkValue;
      window.open(
        `./#/typicalCase/caseDetail?row_pkValue=${row_pkValue}`,
        "_blank"
      );
      // this.$router.push({
      //   path: "/typicalCase/caseDetail",
      //   query: {
      //     row_pkValue: row_pkValue,
      //   },
      // });
    },

    handlePageChange() {
      this.pageIndex = index;
      this.lafCase();
    },
  },
};
</script>

<style lang='scss' scoped>
.image {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: block;
}
.image1 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: none;
}
@include apply-small {
  .image1 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: block;
  }
  .image {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: none;
  }
}
.caseLine {
  border-bottom: 1px solid #e5e5e5;
}
.details {
  width: 100%;
  height: 60px;
  overflow: hidden;
  height: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 auto;
  line-height: 60px;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
}
.title {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 30px;
  margin-right: 10px;
  width: 900px;
  @include apply-small {
    width: 360px;
  }
  // width: 80%;
}
.caseDate {
  color: #999;
}
.fenYe {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
</style>