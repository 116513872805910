export default {
    state: {
      reportObj: {}
    },
    actions: {
      setReportDetail ({ commit }, params) {
        commit({type: 'updateDetail', params})
      }
    },
    mutations: {
      updateDetail (state, data) {
        state.reportObj = data.params
      }
    }
  }
  