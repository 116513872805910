<style lang="scss" scoped>
::v-deep {
  .v-model {
    opacity: 0.8;
  }
}
.online-container {
  margin: 11px 0 80px 70px;
  @include apply-small {
    margin: 30px auto;
  }
  // width: 750px;
  .online-step-container {
    height: 80px;
    border: 2px dashed #cd242a;
    position: relative;
    &::after {
      content: "";
      display: block;
      background: #fff;
      height: 4px;
      width: 90%;
      position: absolute;
      left: 5%;
      bottom: -2px;
    }
    .step-item {
      float: left;
      width: 33.33%;
      text-align: center;
      color: #999;
      font-size: 15px;
      &.active {
        color: #cd242a;
        .step-num {
          background: #cd242a;
          color: #fff;
        }
      }
    }
    .step-num {
      height: 60px;
      width: 60px;
      background: #e2e2e2;
      border-radius: 50%;
      font-size: 14px;
      color: #bbb;
      margin: -30px auto 0 auto;
      .num {
        display: block;
        text-align: center;
        font-size: 26px;
        height: 30px;
      }
    }
  }
  .donate-form {
    margin-top: 30px;
    ::v-deep.el-radio__input.is-checked + .el-radio__label {
      color: #cd242a;
    }
    ::v-deep.el-radio__input.is-checked .el-radio__inner {
      background: #cd242a;
      border-color: #cd242a;
    }
    .money {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      background: #e6e6e6;
      border-radius: 6px;
      text-align: center;
      font-weight: bold;
      font-size: 17px;
      cursor: pointer;
      margin-right: 10px;
      @include apply-small {
        margin-bottom: 20px;
      }
      &.active {
        background: #cd242a;
        color: #fff;
      }
      &.other {
        padding-right: 0;
      }
    }
    .money-input {
      margin-left: 20px;
      width: 100px;
      display: block;
      float: right;
    }
    .wx-pay,
    .zfb-pay {
      display: inline-block;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 44px;
      position: relative;
      cursor: pointer;
    }
    .wx-pay.select,
    .zfb-pay.select {
      border: 1px solid #e9630a;
      &::after {
        content: "";
        display: block;
        height: 15px;
        width: 15px;
        background: url("../../assets/images/donate/icon_select.png") no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .wx-pay {
      width: 140px;
      background-image: url("../../assets/images/donate/wx.jpg");
    }
    .zfb-pay {
      width: 180px;
      margin-left: 40px;
      background-image: url("../../assets/images/donate/zfb.jpg");
      @include apply-small {
        margin-left: 10px;
      }
    }
    .submit-btn {
      width: 250px;
    }
  }
  .qrcode-container {
    margin: 60px auto;
    width: 500px;
    text-align: center;
    color: #333;
    font-size: 16px;
    letter-spacing: 4px;
    font-weight: bold;
    border: solid 1px #e5e5e5;
    padding: 20px 0;
    border-radius: 10px;
    .qrcode {
      height: 400px;
      width: 400px;
    }
  }
  .before-btn {
    width: 250px;
    display: block;
    margin: auto;
  }
  .result-container {
    .result {
      height: 200px;
      width: 400px;
      background-repeat: no-repeat;
      background-size: 100px 100px;
      background-position: left center;
      margin: 100px auto;
      text-indent: 120px;
      font-size: 18px;
      padding-top: 85px;
      letter-spacing: 2px;
      &.success {
        background-image: url("../../assets/images/donate/success.png");
      }
      &.error {
        background-image: url("../../assets/images/donate/error.png");
      }
    }
    .back-btn {
      width: 250px;
      display: block;
      margin: auto;
    }
  }
}
.out-color {
  .saveMobile {
    display: none;
  }
  position: relative;
  ::v-deep .el-dialog {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0;
    width: 550px;
    .el-dialog__body {
      width: 550px;
      padding: 20px;
    }
    .dialog-footer {
      display: flex;
      justify-content: space-around;
      .button-img {
        width: 200px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        font-size: 16px;
      }
      .save {
        background-color: #919399;
        cursor: pointer;
      }
      .cancel {
        background-color: #919399;
        cursor: pointer;
      }
    }
  }
  ::v-deep .el-icon {
    display: none;
  }
  .out-color-close {
    position: absolute;
    top: 10px;
    right: -680px;
    font-weight: 300;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: rgba(0, 0, 0, 50%);
    border-radius: 20px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
  }
  @media screen and (max-width: 420px) {
    .out-color-close {
      position: absolute;
      top: 0px;
      right: 0px;
    }
    .saveInfo {
      display: none;
    }
    .saveMobile {
      display: block;
    }
  }
}
.invoice-detail {
  .invoice-detail-content {
    margin-top: 18px;
    .detail {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .invoice-one {
    margin-left: 20px;
  }
  .mark {
    color: red;
  }
}
#canvas {
  width: 550px;
  height: 777px;
  // position: relative;
  @include apply-small {
    width: 100%;
  }
}
</style>

<template>
  <div class="online-container">
    <div class="online-step-container">
      <div class="step-item" :class="{ active: currentStep === 1 }">
        <div class="step-num">
          <span class="num">1</span>
          <span class="num-en">one</span>
        </div>
        确认捐款信息
      </div>
      <div class="step-item" :class="{ active: currentStep === 2 }">
        <div class="step-num">
          <span class="num">2</span>
          <span class="num-en">two</span>
        </div>
        在线支付
      </div>
      <div class="step-item" :class="{ active: currentStep === 3 }">
        <div class="step-num">
          <span class="num">3</span>
          <span class="num-en">three</span>
        </div>
        完成捐款
      </div>
    </div>
    <el-form
      v-show="currentStep === 1"
      ref="donateForm"
      class="donate-form"
      :model="donate"
      :label-width="$smallScreen ? '100px' : '140px'"
      :rules="rules"
    >
      <el-form-item label="金额：" prop="payMoney">
        <span
          class="money"
          v-for="(item, idx) in defaultMoney"
          :key="idx"
          :class="{ active: item === donate.payMoney }"
          @click="selectMoney(item)"
          >{{ item }}</span
        >
        <span class="money other" :class="{ active: selectOther }"
          >其他<el-input-number
            style=""
            class="money-input"
            v-model="otherMoney"
            :precision="2"
            :step="1"
            :min="0"
            :controls="false"
            @focus="handleSelectOtherMoney"
        /></span>
      </el-form-item>
      <!-- <el-form-item label="捐赠人类型：">
        <el-radio v-model="donate.payPeopleType" :label="0">个人</el-radio>
        <el-radio v-model="donate.payPeopleType" :label="1">企业</el-radio>
      </el-form-item> -->
      <el-form-item
        v-if="this.donate.payInvoice !== 0"
        label="捐赠人姓名："
        prop="payName"
        :rules="
          donate.payInvoice === 1
            ? {
                required: true,
                message: '请输入捐赠人姓名',
                trigger: ['blur', 'change'],
              }
            : {}
        "
      >
        <el-input
          v-model="donate.payName"
          :maxlength="50"
          placeholder="请如实填写捐赠人姓名"
        />
      </el-form-item>
      <el-form-item
        v-if="this.donate.payInvoice !== 1"
        label="捐赠人姓名："
        prop="payName"
        :rules="
          donate.payInvoice === 1
            ? {
                required: true,
                message: '请输入捐赠人姓名',
                trigger: ['blur', 'change'],
              }
            : {}
        "
      >
        <el-input
          v-model="donate.payName"
          :maxlength="50"
          placeholder="如需实名捐赠证书请填写  (匿名捐赠可不填)"
        />
      </el-form-item>

      <el-form-item
        v-if="this.donate.payInvoice !== 1"
        label="捐赠人所在单位："
        prop="payUnit"
        :rules="
          donate.payInvoice === 1
            ? {
                required: true,
                message: '请输入捐赠人所在单位',
                trigger: ['blur', 'change'],
              }
            : {}
        "
      >
        <el-input v-model="donate.payUnit" :maxlength="50" placeholder="选填" />
      </el-form-item>

      <el-form-item
        v-if="this.donate.payInvoice !== 0"
        label="联系方式："
        prop="payPhone"
        :rules="
          donate.payInvoice === 1
            ? {
                required: true,
                validator: checkPhone,
                trigger: ['blur', 'change'],
              }
            : {}
        "
      >
        <el-input
          v-model="donate.payPhone"
          :maxlength="50"
          placeholder="请填写联系方式"
        />
      </el-form-item>
      <el-form-item
        v-if="this.donate.payInvoice !== 1"
        label="联系方式："
        prop="payPhone"
        :rules="
          donate.payInvoice === 1
            ? {
                required: true,
                validator: checkPhone,
                trigger: ['blur', 'change'],
              }
            : {}
        "
      >
        <el-input
          v-model="donate.payPhone"
          :maxlength="50"
          placeholder="选填"
        />
      </el-form-item>
      <!-- <el-form-item
        v-if="this.donate.payInvoice !== 0"
        label="开票信息："
        prop="ticketInfo"
        :rules="
          donate.payInvoice === 1
            ? {
                required: true,
                message: '请填写开票信息',
                trigger: ['blur', 'change'],
              }
            : {}
        "
      >
        <el-input
          v-model="donate.ticketInfo"
          :maxlength="50"
          placeholder="请如实填写开票信息"
        />
      </el-form-item> -->
      <el-form-item
        v-if="this.donate.payInvoice !== 0"
        label="邮寄地址："
        prop="mailAddress"
        :rules="
          donate.payInvoice === 1
            ? {
                required: true,
                message: '请输入邮寄地址',
                trigger: ['blur', 'change'],
              }
            : {}
        "
      >
        <el-input
          v-model="donate.mailAddress"
          :maxlength="50"
          placeholder="请填写邮寄地址   (注：基于成本，100元以下的票据邮费由捐赠者自付)"
        />
      </el-form-item>
      <el-form-item
        v-if="this.donate.payInvoice !== 0"
        label="捐赠人所在单位："
        prop="payUnit"
      >
        <el-input v-model="donate.payUnit" :maxlength="50" placeholder="选填" />
      </el-form-item>
      <!-- <el-form-item label="地址：">
        <el-input :maxlength="200" placeholder="请输入地址" />
      </el-form-item> -->
      <!-- <el-form-item label="是否开票：">
        <el-radio
          v-model="donate.payInvoice"
          :label="1"
          @change="payInvoiceChange"
          >是</el-radio
        >
        <el-radio
          v-model="donate.payInvoice"
          :label="0"
          @change="payInvoiceChange"
          >否</el-radio
        >
      </el-form-item> -->
      <el-form-item label="付款方式：">
        <span
          @click="handleWeiXin"
          class="wx-pay"
          :class="{ select: donate.payType === 0 }"
        ></span>
        <span
          class="zfb-pay"
          :class="{ select: donate.payType === 1 }"
          @click="donate.payType = 1"
        ></span>
      </el-form-item>
      <el-form-item label="捐赠留言：">
        <el-input
          v-model="donate.payMessage"
          :rows="6"
          :maxlength="50"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button class="submit-btn" @click="handleSubmitDonate"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
    <div v-show="currentStep === 2">
      <div v-if="isWeiXin && isMobile && donate.payType === 0">
        <div style="width: 500px; height: 400px"></div>
      </div>
      <div v-else class="qrcode-container">
        <img class="qrcode" ref="qrcode" /><br />
        <span style="margin-top: 20px">{{
          donate.payType === 0 ? "微信支付" : "支付宝支付"
        }}</span>
      </div>
      <el-button class="before-btn" @click="handleBeforeDonate"
        >上一步</el-button
      >
    </div>
    <div class="result-container" v-show="currentStep === 3">
      <div
        class="result"
        :class="[result.type === 'success' ? 'success' : 'error']"
      >
        {{ result.msg }}
      </div>
      <el-button class="back-btn" @click="handleBeforeDonate">返回</el-button>
    </div>
    <!---外面的保存-->
    <div class="out-color">
      <el-dialog :visible="showCertificate" :modal="true" top="0vh" center>
        <canvas id="canvas" width="550" height="777"></canvas>
        <div class="out-color-close" @click="handleCancel">
          <i class="el-icon-close"></i>
        </div>
        <span slot="footer" class="dialog-footer">
          <div
            class="button-img save saveMobile"
            @click="downCertificateMobile"
          >
            请截图保存
          </div>
          <div class="button-img save saveInfo" @click="downCertificate">
            保 存
          </div>
          <div class="button-img cancel" @click="handleInvoice">申请开票</div>
        </span>
      </el-dialog>
    </div>
    <!---申请开票--->
    <div class="invoice-detail">
      <el-dialog
        title="申请开票方式"
        :modal="false"
        @close="cancelInvoice"
        :visible.sync="invoiceShow"
        width="50%"
        center
      >
        <h3 class="invoice-detail-content">
          <span class="detail">1. </span>请您将相关信息发至邮箱<span
            class="mark"
            >jslaf2007@163.com</span
          >，邮件标题请填写<span class="mark">"捐赠开票"</span
          >，信息内容包括<span class="mark">微信</span>或<span class="mark"
            >支付宝</span
          ><span class="mark">商户单号</span>、<span class="mark">票据抬头</span
          >、<span class="mark">收件人姓名</span>、<span class="mark"
            >联系方式</span
          >、<span class="mark">邮寄地址</span>。以上<span class="mark"
            >信息务必正确</span
          >，否则<span class="mark">影响开票</span>与<span class="mark"
            >送达</span
          >。
        </h3>
        <h3 class="invoice-detail-content">
          <span class="detail">2. </span> 基于捐赠成本考虑，捐赠<span
            class="mark"
            >100元以下</span
          >的票据邮寄费用由捐赠人<span class="mark">自付</span>。
        </h3>
        <h3 class="invoice-detail-content">
          <span class="detail">3. </span>商户单号查询方式：
        </h3>
        <h3 class="invoice-detail-content invoice-one">
          ①若使用<span class="mark">微信捐赠</span>，您可以在<span class="mark"
            >“微信-我-支付-钱包-账单”</span
          >中找到这笔捐赠，查看<span class="mark">商户单号</span>。
        </h3>
        <h3 class="invoice-one">
          ②若使用<span class="mark">支付宝捐赠</span>，您可以在<span
            class="mark"
            >“支付宝-我的-账单”</span
          >中找到这笔捐赠，查看<span class="mark">商户单号</span>。
        </h3>
        <h3 class="invoice-detail-content">
          <span class="detail">4. </span>按照财务制度相关规定，<span
            class="mark"
            >跨年发票不能申请</span
          >，<span class="mark">发票申请请在当年内完成</span>。
        </h3>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="invoiceShowCancel">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <!--里面的取消-->
    <div>
      <el-dialog
        title="确认保存"
        :modal="false"
        @close="cancelIn"
        :visible.sync="centerDialogVisible"
        width="50%"
        center
      >
        <span>关闭后证书无法保存，是否确认保存证书？</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="cancelIn">取 消</el-button>
          <el-button type="info" @click="saveIn">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="确认截图保存"
        :modal="false"
        @close="cancelScreenshot"
        :visible.sync="screenshotVisible"
        width="50%"
        center
      >
        <span>确认截图截图保存了吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="cancelScreenshot">取 消</el-button>
          <el-button type="info" @click="saveScreenshot"> 确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
const checkPhone = (rule, value, cb) => {
  const exp =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (!value) {
    cb(new Error("请输入联系方式"));
  } else if (value !== "" && !exp.test(value)) {
    cb(new Error("联系方式格式不正确"));
  } else cb();
};
// 交易超时时间
const DEFAULT_TIME = 5 * 60 * 1000;

import newAxios from "../../utils/weChatRequest.js";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      invoiceShow: false,
      setCintent: 0,
      centerDialogVisible: false,
      screenshotVisible: false,
      checkPhone,
      currentStep: 1,
      selectOther: false,
      otherMoney: 0,
      orderId: "",
      defaultMoney: [30, 50, 80, 100, 200],
      timer: null,
      timeoutTimer: null,
      showCertificate: false,
      donate: {
        payMoney: 0,
        payName: "",
        payPhone: "",
        payUnit: "",
        openTicket: "",
        mailAddress: "",
        ticketInfo: "",
        payInvoice: 0,
        payPeopleType: 0,
        payAnonymity: 0,
        payMessage: "",
        payType: 0,
      },
      rules: {
        payMoney: [
          { required: true, message: "请选择金额", trigger: "change" },
        ],
        // payName: [{ required: true, message: "请输入捐赠人姓名" }],
        // mailAddress:[{required: true, message: "请输入邮寄地址"}],
        // payPhone: [
        //   { required: true, message: "请输入联系方式" },
        //   { validator: checkPhone, trigger: "blur" },
        // ],
      },
      result: {
        type: "success",
        msg: "支付成功，感谢您的捐赠!",
      },
      isWeiXinCertificate: false, // 判断证书
      isMobileCertificate: false, //判断证书
      isWeiXin: false,
      isMobile: false,
      openId: "",
    };
  },
  created() {
    this.isWeiXinAndMobileFun();
    if (this.isWeiXin && this.isMobile) {
      this.openId = this.$router.currentRoute.params.openId;
    }
    // 如果是微信浏览器且是移动端且没有openId则进行页面跳转
    if (this.isWeiXin && this.isMobile && !this.openId) {
      window.location.href = "http://www.jslaf.org.cn/laf/laf/WX/getCode.ajax";
    }
  },
  methods: {
    // 确认开票弹出框
    handleInvoice() {
      this.invoiceShow = true;
    },
    // 关闭开票
    cancelInvoice() {
      this.invoiceShow = false;
    },
    //  确定
    invoiceShowCancel() {
      this.invoiceShow = false;
    },
    // 外面的取消
    handleCancel() {
      this.centerDialogVisible = true;
    },

    // 里面的取消
    cancelIn() {
      this.centerDialogVisible = false;
      this.showCertificate = false;
    },
    // 移动端证书取消
    cancelScreenshot() {
      this.screenshotVisible = false;
    },
    // 移动端证书保存
    saveScreenshot() {
      this.screenshotVisible = false;
      this.showCertificate = false;
    },
    // 里面的保存
    saveIn() {
      this.downCertificate();
      this.centerDialogVisible = false;
    },
    // 移除校验
    payInvoiceChange() {
      this.$refs.donateForm.clearValidate();
    },
    handleWeiXin() {
      this.donate.payType = 0;
      if (this.isMobile && !this.openId) {
        alert(
          "因微信不提供移动浏览器支付功能，请用支付宝支付，或登录江苏省法律援助基金会微信公众号，进行微信支付，给您带来的不便，敬请理解。"
        );
      }
    },
    //判断是否是微信浏览器的函数
    isWeiXinAndMobileFun() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isWeiXin = true;
      } else {
        this.isWeiXin = false;
      }
      if (ua.match(/Android|webOS|iPhone|iPod|BlackBerry/i)) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    // 获取openId
    getOpenId() {
      newAxios({
        url: "http://www.jslaf.org.cn/laf/WX/getCode.ajax",
        method: "get",
      });
    },
    onBridgeReady(data) {
      newAxios({
        url: "http://www.jslaf.org.cn/laf/WX/jsapiPay.ajax",
        method: "post",
        dataType: "json",
        data: {
          ...data,
          openid: this.openId,
        },
      }).then((res) => {
        this.currentStep = 2;
        let result = res.data;
        if (result.code == 200) {
          this.orderId = result.object.orderNo;
          // 微信调起支付接口
          WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: result.object.appId, //公众号名称，由商户传入
              timeStamp: result.object.timeStamp, //时间戳，自1970年以来的秒数
              nonceStr: result.object.nonceStr, //随机串
              package: result.object.package,
              signType: result.object.signType, //微信签名方式：
              paySign: result.object.paySign, //微信签名
            },
            (res) => {
              console.log(res);
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                this.$nextTick(() => {
                  this.startGetOrderStatus();
                });
              }
            }
          );
        } else {
          alert("发生异常");
        }
      });
    },
    selectMoney(m) {
      this.selectOther = false;
      this.donate.payMoney = m;
    },
    handleSelectOtherMoney() {
      this.donate.payMoney = 0;
      this.selectOther = true;
    },
    handleSubmitDonate() {
      if (this.isMobile && !this.openId && this.donate.payType == 0) {
        alert(
          "因微信不提供移动浏览器支付功能，请用支付宝支付，或登录江苏省法律援助基金会微信公众号，进行微信支付，给您带来的不便，敬请理解。"
        );
      } else {
        if (this.selectOther) this.donate.payMoney = this.otherMoney;
        if (this.donate.payMoney === 0) {
          this.$message({
            message: "请选择捐赠金额",
            type: "warning",
          });
          return;
        }
        this.$refs.donateForm.validate((valid) => {
          if (valid) {
            if (this.isWeiXin && this.isMobile && this.donate.payType === 0) {
              // 调用微信支付
              this.onBridgeReady(this.donate);
            } else {
              this.$axios({
                url:
                  this.donate.payType === 0
                    ? "/laf/WX/pay.ajax"
                    : "/laf/ali/pay.ajax",
                method: "post",
                data: this.donate,
              })
                .then((res) => {
                  if (res.code !== "1000") {
                    this.$message.error(res.msg);
                    return;
                  }
                  this.$refs.qrcode.src = res["qr_code"];
                  this.orderId = res.outTradeNo;
                  this.currentStep = 2;
                  this.$nextTick(() => {
                    this.startGetOrderStatus();
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          }
        });
      }
    },
    handleBeforeDonate() {
      this.currentStep = 1;
    },

    startGetOrderStatus() {
      this.getOrderStatus();
      this.timer = setInterval(() => {
        this.setCintent++;
        if (this.setCintent < 500) {
          this.getOrderStatus();
        }
      }, 2000);
      this.timeoutTimer = setTimeout(() => {
        this.result = {
          type: "error",
          msg: "支付失败，交易超时。",
        };
        this.currentStep = 3;
      }, DEFAULT_TIME);
    },
    getOrderStatus() {
      this.$axios({
        url:
          this.donate.payType === 0
            ? "/laf/WX/queryOrder.ajax"
            : "/laf/ali/queryOrder.ajax",
        method: "post",
        data: {
          outTradeNo: this.orderId,
          orderid: this.orderId,
        },
      })
        .then((res) => {
          if (res.code === "1000") {
            // 支付成功
            // if(true) {// 支付成功
            clearInterval(this.timer);
            clearTimeout(this.timeoutTimer);
            this.currentStep = 3;
            console.log(res);

            // 支付成功后弹窗显示证书
            this.showCertificate = true;
            setTimeout(() => {
              console.log(document.getElementById("canvas"));
              this.getCeritificate(
                this.donate.payName,
                this.donate.payMoney,
                res.certificateNumber
              );
            }, 100);
          }
        })
        .catch((err) => console.log(err));
    },
    // 展示证书对话框，并写入证书图片   捐赠人名称   捐款额度   证书编号
    getCeritificate(payName, payMoney, certificateId) {
      let load = Loading.service({
        text: "证书生成中...",
      });

      console.log(document.getElementById("canvas"));
      let ctx = document.getElementById("canvas").getContext("2d");
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = require("../../assets/images/donate/certificate.jpg");

      let seal = new Image();
      seal.setAttribute("crossOrigin", "anonymous");
      seal.src = require("../../assets/images/donate/seal.png");

      // 日期数据处理
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let finallyDate =
        year + " " + "年" + " " + month + " " + "月" + " " + day + " " + "日";
      let str1 = `       兹 有   ${payName}   ( 先 生 / 女 士 )`;
      let strEpr = `       兹 有   ${payName}`;
      let str2 = `向 江 苏 省 法 律 援 助 基 金 会 捐 `;
      let str3 = `赠   ${payMoney}   元。`;
      let str4 = `       特 颁 此 证，以 致 谢 忱。`;
      img.onload = async () => {
        await ctx.drawImage(img, 0, 0, 550, 777);
        await ctx.drawImage(seal, 280, 510, 150, 150);
        ctx.font = "20px Microsoft YaHei";

        //绘制下划线
        let nameWidth = ctx.measureText(payName);
        let moneyWidth = ctx.measureText(payMoney);
        await ctx.beginPath();
        await ctx.moveTo(219, 285);
        await ctx.lineTo(219 + nameWidth.width + 10, 285);
        await ctx.closePath();
        await ctx.stroke();
        await ctx.beginPath();
        await ctx.moveTo(153, 345);
        await ctx.lineTo(153 + moneyWidth.width + 10, 345);
        await ctx.closePath();
        await ctx.stroke();

        // 企业和个人的称呼不同
        if (this.donate.payPeopleType) {
          await ctx.fillText(strEpr, 120, 280);
        } else {
          await ctx.fillText(str1, 120, 280);
        }
        await ctx.fillText(str2, 120, 310);
        await ctx.fillText(str3, 120, 340);
        await ctx.fillText(str4, 120, 370);
        await ctx.fillText(finallyDate, 269, 610);
        await ctx.fillText(certificateId, 232, 703);
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          load.close();
        });
      };
    },
    // 移动端截图保存
    downCertificateMobile() {
      this.screenshotVisible = true;
    },
    // 保存证书
    downCertificate() {
      let canvasImg = document.getElementById("canvas");
      let strDataURL = canvasImg.toDataURL("image/jpeg");
      const aTag = document.createElement("a");
      aTag.download = "捐赠证书.jpeg";
      aTag.href = strDataURL;
      aTag.click();
      this.showCertificate = false;
    },
  },
  destroyed() {
    this.timer && clearInterval(this.timer);
    this.timeoutTimer && clearTimeout(this.timeoutTimer);
  },
};
</script>