export default {
    state: {
        regulationObj: {}
    },
    actions: {
      setRegulationDetail({ commit }, params) {
        commit({type: 'updateDetail', params})
      }
    },
    mutations: {
      updateDetail (state, data) {
        state.regulationObj = data.params
      }
    }
  }
  