<template>
  <div class="home-container">
    <el-form
      :model="formData"
      :rules="rules"
      ref="form"
      label-position="right"
      size="medium"
      label-width="110px"
    >
      <el-row :gutter="40">
        <el-col :span="11">
          <h3>上传附件</h3>
          <div class="tips">
            注：请先下载 &nbsp;&nbsp;&nbsp;&nbsp;<a
              @click="handleDownload('项目申报')"
              >项目申报模版</a
            >
          </div>
          <el-form-item label="" prop="prjFile" label-width="0">
            <el-upload
              ref="upload"
              class="upload-wrap"
              drag
              action=""
              :http-request="uploadFile"
              :limit="1"
              :on-remove="handleRemove"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
                <div class="el-upload__tip" slot="tip">
                  附件类型支持：doc,docx
                </div>
              </div>
            </el-upload>
          </el-form-item>
          <div class="changeFile">
            如需更改 &nbsp; 上传附件 ,请先删除现有已上传附件
          </div>
        </el-col>
        <el-col :span="13">
          <h3>项目信息</h3>
          <!-- 项目名称 -->
          <el-form-item label="项目名称" prop="prjName" class="reportList">
            <el-input v-model="formData.prjName"></el-input>
          </el-form-item>
          <!-- 项目分类 -->
          <el-form-item label="项目分类" prop="prjClassify" class="reportList">
            <el-select
              v-model="formData.prjClassify"
              placeholder="请选择项目分类"
              style="width: 100%"
            >
              <el-option label="公益基金" value="GYJJ"></el-option>
              <el-option label="专项基金" value="ZXJJ"></el-option>
              <el-option label="资助项目" value="ZZXM"></el-option>
              <el-option label="筹款项目" value="CKXM"></el-option>
            </el-select>
          </el-form-item>

          <!-- 项目金额 -->
          <el-form-item label="项目金额" prop="prjMoney" class="reportList">
            <el-input v-model="formData.prjMoney" placeholder="万元"></el-input>
          </el-form-item>
          <el-form-item label="区域选择" prop="applyDept" class="reportList">
            <el-cascader
              :props="props"
              v-model="formData.applyDept"
              style="width: 100%"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-button @click="submitForm">提交</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { downloadFileStream } from "@/utils/tools";
export default {
  name: "Report",
  data() {
    const _this = this;
    return {
      formData: {
        prjClassify: "",
        prjName: "",
        prjMoney: "",
        applyDept: "",
        prjFile: "",
      },
      rules: {
        prjClassify: [{ required: true, message: "请输入", trigger: "blur" }],
        prjName: [{ required: true, message: "请输入", trigger: "blur" }],
        prjMoney: [{ required: true, message: "请输入", trigger: "blur" }],
        applyDept: [{ required: true, message: "请输入", trigger: "blur" }],
        prjFile: [{ required: true, message: "请上传附件", trigger: "change" }],
      },

      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const { value } = node;
          const res = await _this.$axios.post(
            // "/laf/system/depart/departTree.ajax",
            "/laf/laf/lafProject/departTree.ajax",
            {
              id: value || "",
            }
          );
          const nodes =
            res &&
            res.length &&
            res.map((item) => ({
              label: item.name,
              value: item.id,
            }));
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes);
        },
      },
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return false;
        const { applyDept } = this.formData;
        console.log(applyDept)
        const res = await this.$axios.post("/laf/laf/lafProject/ykSave.ajax", {
          ...this.formData,
          applyDept: applyDept.slice(-1)[0],
          pkValue: "-1",
          _entityName: "org.aweto.laf.entity.LafProject",
          applyRole: 1,
        });
        if (res.state === 0) {
          this.$router.push("/home/list");
          this.$notify({
            title: "成功",
            message: res.msgInfo,
            type: "success",
          });
        }
      });
    },

    async uploadFile(files) {
      const { file } = files;
      const name = file.name;
      if (!/.+.docx?$/.test(file.name)) {
        this.$refs.upload.clearFiles();
        return this.$message({
          title: "失败",
          message: "附件类型支持：doc,docx",
          type: "error",
        });
      }
      const formData = new FormData();
      formData.append("attachId", "org.aweto.laf.entity.LafProject");
      formData.append("pkVal", -1);
      formData.append("fileupload", file);
      const res = await this.$axios.post(
        "/laf/attachment/uploadFile.ajax",
        formData
      );

      if (res.state === "0") {
        this.$notify({
          title: "成功",
          message: res.msgInfo,
          type: "success",
        });
        const { docNo } = res;
        this.formData.prjFile = docNo;
        this.$refs.form.clearValidate("prjFile");
      }
    },

    handleRemove(file, fileList) {
      this.formData.prjFile = "";
    },

    async handleDownload(keyword) {
      // 获取附件fileUrl
      const res1 = await this.$axios({
        method: "post",
        url: "/laf/laf/lafFileDownload/tableList.ajax",
        data: {
          pageNo: 1,
          pageSize: 10,
          condition: `fileName:@${keyword}||`,
        },
      });

      // 根据fileUrl获取附件信息
      const res2 = await this.$axios({
        method: "post",
        url: "/laf/attach/attachInfo/attachList.ajax",
        data: {
          fileId: res1.rows[0].fileUrl,
        },
      });
      const {
        localType,
        localAddress,
        username,
        password,
        filePath,
        saveName,
        fileName,
        fileType,
        id:infoNo
      } = res2[0];

      // 下载附件
      const res3 = await this.$axios({
        method: "post",
        url: "/laf/fileDownloadServlet",
        data: {
          localType,
          localAddress,
          username,
          password,
          filepath: filePath,
          saveName,
          fileName,
          fileType,
          pathType: "absolute",
          encode: "UTF-8",
          infoNo,
          viewMode:false
        },
        responseType: "blob",
      });
      downloadFileStream(res3, fileName);
    },

    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style lang='scss' scoped>
.home-container {
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 23px 25px;
  border: 1px solid #e5e5e5;
  h3 {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 28px;
    color: #606266;
  }
}
.reportList {
  margin-bottom: 50px;
}
.upload-wrap {
  text-align: center;
  width: 491px;
  height: 265px;
  background: #ffffff;
  box-shadow: 0px 10px 11px 0px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-top: 12px;
  ::v-deep {
    .el-upload {
      height: 207px;
    }
    .el-upload-dragger {
      height: 207px;
    }
  }
}
::v-deep .el-textarea__inner {
  resize: none;
}
.tips {
  text-align: center;
  color: #606266;
  font-size: 14px;
  a {
    color: #409eff;
  }
}
.changeFile {
  font-size: 14px;
  margin-left: 90px;
  color: #606266;
}
</style>