<template>
  <div label="通知公告">
    <img class="image" src="@/assets/images/star3.png" alt="" />
    <img class="image1" src="@/assets/images/star2.jpg" alt="" />
    <div class="home-container">
      <h1 v-if="isShowTip" class="search-content">没有搜索到匹配结果</h1>
      <div class="line"></div>
  
      <div
        class="details"
        v-for="(item, index) in indexList"
        :key="index"
        @click="goDetail(item)"
      >
          <div class="title">
            <span v-if="item.lafNewsType" v-html="'【' + item.lafNewsType + '】'" class="type"></span>
            <span v-html="item.lafNewsTitle" class="content"></span>
            <span class="title-time">{{item.lafNewsTime}}</span>
          </div>
      </div>
       <!-- 分页 -->
        <el-pagination
          class="paging"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrent"
          @prev-click="handlePrev"
          @next-click="handleNext"
        >
        </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      isShowTip:false,
      // 搜索
      indexList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },
  watch: {
    $route: {
      handler (val) {
        this.lafNewsTitle = val.query.keywords
        this.handleSearchList()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 详情
      goDetail(data) {
      const lafTableId = data.lafTableId;
      const lafTableName = data.lafTableName
      this.$router.push({
        name: "searchDetail",
        query: {
          lafTableId: lafTableId,
          lafTableName:lafTableName
        },
      });
    },
    // 列表接口
      handleSearchList () {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafSearchTable/tableList.ajax",
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          condition: `lafNewsTitle:@${this.lafNewsTitle}||`
        },
      }).then((res) => {
        // 清空数据
        this.indexList = []
        // 有结果
        if (res.rows && res.rows.length) {
          this.total = res.totalRows;
          res.rows.forEach(item => {
            item.lafNewsTitle = item.lafNewsTitle.replace(new RegExp(this.lafNewsTitle, 'g'), match => `<span style="color: red;">${match}</span>`);
            // item.lafNewsType = item.lafNewsType.replace(new RegExp(this.lafNewsTitle, 'g'), match => `<span style="color: red;">${match}</span>`);
          })
          this.indexList = res.rows;
          this.isShowTip = false
        } else {
          // 无结果
          this.isShowTip = true
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 当前页面
    handleCurrent(e) {
      this.pageNo = e;
      this.handleSearchList();
    },
    //上一页
    handlePrev(e) {
      this.pageNo = e;
      this.handleSearchList();
    },
    // 下一页
    handleNext(e) {
      this.pageNo = e;
      this.handleSearchList();
    },
  },
};
</script>

<style lang='scss' scoped>

  .content {
    display: inline-block;
    margin-left:8px;
@include apply-small {
    width:220px;
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
}
.title {
  width:100%;
}
.search-content{
  text-align: center;
  margin-top:5px;
  margin-bottom: 20px;
  color: #bbb;;
}
.title-content{
  display: inline-block;
  margin-left:15px;
}
.type{
  float: left;
  display: inline-block;
}
.title-time{
  float: right;
  margin-right:15px;
  font-size: 14px;
  color: #bbb;
}
.time {
  color: #bbb;
  font-size: 12px;
  float: right;
  margin-right: 10px;
}
.image {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: block;
}
.image1 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: none;
}
@include apply-small {
  .image1 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: block;
  }
  .image {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: none;
  }
}
.contentItem {
  height: 60px;
  margin-top: 20px;
  text-decoration: none;
}
.line {
  width: 100%;
  height: 1px;
  border-top: 1px solid #e5e5e5;
  margin-top: 38px;
  margin: 0 auto;
}
.details {
  width: 100%;
  height: 60px;
  overflow: hidden;
  height: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 auto;
  line-height: 60px;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
}
// .details :nth-child(2) {
//   float: right;
//   margin-right: 40px;
// }
.lafPage {
  text-align: center;
  margin-top: 50px;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #cd242a;
}
::v-deep .paging {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>