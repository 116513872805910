<template>
  <div class="mainContent">
    <h3 class="title">{{ messageInfo.ourTitle }}</h3>
    <div class="link"></div>
    <p class="content" v-html="messageInfo.ourContent"></p>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      messageInfo: "",
    };
  },
  mounted() {
    this.message();
  },
  methods: {
    message() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafOur/find.ajax",
        data: {
          pkValue: "4",
          _entityName: "org.aweto.laf.entity.LafOur",
        },
      })
        .then((res) => {
          // console.log(res);
          this.messageInfo = res.recorder;
          // console.log(this.messageInfo);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.mainContent {
  .title {
    text-align: center;
    font-size: 20px;
    color: #333333;
  }
  .link {
    margin-top: 20px;
    border-top: solid #e5e5e5 1px;
  }
  .content {
    margin: 15px;
  }
}
</style>