import axios from "axios";
import qs from "qs";
import { Message } from "element-ui";

const Tip = (msg) => {
  Message({
    type: "error",
    message: msg,
    forbidClick: true,
    withCredentials: true,
  });
};
const baseURL = process.env.NODE_ENV === "development" ? "/api" : "";

const access_token = () =>
  sessionStorage.getItem("token") || "NY_SOEyxfX7RG6Oa2uXRlEeoMWpxgULe";

var instance = axios.create({
  baseURL,
  timeout: 1000 * 120,
  withCredentials: true,
  // headers: { Authorization: access_token },
});
instance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = access_token();
    const { params, method, data } = config;
    if (method === "get") {
      config.params = { ...params, access_token: access_token() };
    } else if (method === "post") {
      if (data instanceof FormData) {
        data.append("access_token", access_token());
      } else {
        config.data = qs.stringify({ ...data, access_token: access_token() });
      }
    }

    return config;
  },
  (error) => Promise.error(error)
);

instance.interceptors.response.use(
  (res) =>
    res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res),
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
