<template>
  <div class="home-container">
    <!-- 时讯要闻 -->
    <p class="boundary"></p>
    <div class="block-title-wrap">
      <span class="block-title">时讯要闻</span>
      <!-- 时讯要闻 导航 列表 -->
      <div class="news-wrap">
        <el-row type="flex" :justify="$smallScreen ? 'center' : 'end'">
          <el-radio-group v-model="newsNavIndex">
            <el-radio-button
              @click.native="handleGoList(item.label)"
              @mouseenter.native="handleNewsNavOver(item.label)"
              :label="item.label"
              v-for="item in newsNavTabs"
              :key="item.label"
              >{{ item.title }}</el-radio-button
            >
          </el-radio-group>
        </el-row>
        <el-row class="home-news">
          <el-col class="news-left" :span="12">
            <div class="newContent" @click="handleImg(currentNews)">
              <img
                class="newImg"
                v-show="currentNews.newsPh"
                :src="newBaseurl + currentNews.newsPh"
                @error="handleNoImg('big')"
                alt=""
              />
              <h5>{{ currentNews.newsTitle }}</h5>
            </div>
          </el-col>
          <el-col class="news-right" :span="12" :xs="24">
            <ul class="news-right">
              <li
                class="news-item"
                v-for="(item, index) in news"
                :key="index"
                :class="newsActiveIndex === index ? 'active' : ''"
                @mouseover="handleNews(item, index)"
                @click="handleGoDetail(item)"
              >
                <div class="showImg">
                  <img
                    :src="newBaseurl + item.newsPh"
                    alt=""
                    class="news-img"
                    @error="handleNoImg()"
                  />
                </div>
                <div class="news-content">
                  <h5>{{ item.newsTitle }}</h5>
                  <p class="content">{{ item.newsText }}</p>
                </div>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 时讯要闻导航
      newsNavTabs: [
        { label: "01", title: "时政要闻" },
        { label: "02", title: "司法行政要闻" },
        { label: "03", title: "本会动态" },
      ],
      newsNavIndex: "03",
      newsActiveIndex: 0,
      news: [],
      currentNews: {},
    };
  },
  mounted() {
    // 时政要闻
    this.getNews("03");
    console.log(this.$smallScreen);
  },
  methods: {
    handleNews(item, index) {
      this.newsActiveIndex = index;
      this.currentNews = item;
    },
    handleNewsNavOver(label) {
      this.newsNavIndex = label;
      this.newsActiveIndex = 0;
      this.getNews(label);
    },
    //  时政要闻接口
    getNews(id) {
      this.$axios
        .post("/laf/laf/lafNews/tableList.ajax", {
          pageSize: "10",
          pageNo: "1",
          _entityName: "org.aweto.laf.entity.LafNews",
          condition: `newsClas:S_IN${id}||`,
          // condition: "newsClas:S_IN01||",
          orderCondition: "desc",
          orderField: "pubDtm",
        })
        .then((res) => {
          if (res && res.rows) {
            this.news = res.rows.slice(0, 4);
            this.currentNews = res.rows[0];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 列表详情
    toCurrentNews() {
      this.$router.push("/news/currentNews");
    },

    handleGoList(label) {
      // 触发点击事件时，如果是大屏则跳转
      if (!this.$smallScreen) {
        let path;
        if (label === "01") {
          path = "/news/currentNews";
        } else if (label === "02") {
          path = "/news/judicial";
        } else if (label === "03") {
          path = "/news/dynamic";
        }

        this.$router.push(path);
      } else {
        // 如果是小屏则切换tab页
        this.handleNewsNavOver(label);
      }
    },

    handleGoDetail(data) {
      const row_pkValue = data.row_pkValue;

      window.open(`./#/news/detail?row_pkValue=${row_pkValue}`, "_blank");
      // this.$router.push({
      //   path: "/news/detail",
      //   query: {
      //     row_pkValue: row_pkValue,
      //   },
      // });
    },
    handleImg(currentNews) {
      const row_pkValue = currentNews.row_pkValue;
      // console.log(row_pkValue)
      this.$router.push({
        path: "/news/detail",
        query: {
          row_pkValue: row_pkValue,
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.boundary {
  // width: 100%;
  // height:3px;
  // background:#f5f5f5;
  // margin-top:25px;
}

.block-title-wrap {
  margin-top: 20px;
}
// 时讯要闻
.news-wrap {
  @include apply-small {
    margin-top: 20px;
  }
  ::v-deep .el-radio-group {
    float: right;
  }
  ::v-deep
    .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #dc2725 !important;
    box-shadow: -1px 0px 9px 0px #222222 !important;
    border-color: #ccc !important;
  }
  ::v-deep .el-radio-button__inner {
    width: 170px;
    height: 40px;
    line-height: 15px;
    font-size: 18px;
    // margin-bottom: 15px;
  }
}
.home-news {
  margin-top: 15px;
  color: #999999;
  display: flex;

  .news-left {
    height: 430px;
    margin-right: 10px;
    box-sizing: border-box;
    border: 1px solid #ebebeb;
    @include apply-small {
      display: none;
    }
    .newContent {
      position: relative;
      height: 400px;
      padding: 15px;
      cursor: pointer;
      // padding-right: 15px;
    }
    .newImg {
      position: relative;
      width: 555px;
      height: 400px;
      border: 1px solid #ebebeb;
    }

    h5 {
      position: absolute;
      width: 555px;
      bottom: -15px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      text-align: center;
    }
  }

  .news-right {
    .news-item {
      display: flex;
      height: 97px;
      padding: 7px 16px 0;
      cursor: pointer;
      margin-bottom: 14px;

      &.active {
        background-color: #eaeaea;
      }
    }
    .showImg {
      width: 100px;
      height: 82px;
      margin-right: 21px;
      border-radius: 5px;
    }
    .news-img {
      width: 100px;
      height: 82px;
    }

    h5 {
      color: #000;
      font-size: 18px;
      margin-top: 10px;
      font-weight: bold;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .content {
      margin-top: 10px;
      font-size: 14px;
      color: #999999;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>