<template>
  <div class="detail">
    <div class="content">
      <div class="content-all">
        <h3 class="title" v-html="message.achievementTitle"></h3>
        <!-- <p class="date">发布时间: {{ message.publishTime }}</p> -->
      </div>
      <div class="content-data">
        <p v-html="message.achievementContent"></p>

        <div class="download" v-if="message.achievement">
          <div>附件下载：</div>
          <div class="file" @click="handleDownLoad(message.achievement)">
            {{ message.achievementTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFileStream } from "@/utils/tools";
export default {
  name: "projectIntroduce",
  data() {
    return {
      message: {},
      catagory: ''
    };
  },
  watch: {
    '$route.params.id'(val){
      switch(val){
        case 'S_IN0': this.catagory = '@法援案件项目'
        break;
        case 'S_IN1': this.catagory = '@普法宣传项目'
        break;
        case 'S_IN2': this.catagory = '@特色站点项目'
        break;
        case 'S_IN3': this.catagory = '@高校法援项目'
        break;
        default: this.catagory = '@培训奖励项目'
      }
      this.getCaseIntroduce();
    }
  },
  mounted() {
    switch(this.$route.params.id){
      case 'S_IN0': this.catagory = '@法援案件项目'
      break;
      case 'S_IN1': this.catagory = '@普法宣传项目'
      break;
      case 'S_IN2': this.catagory = '@特色站点项目'
      break;
      case 'S_IN3': this.catagory = '@高校法援项目'
      break;
      default: this.catagory = '@培训奖励项目'
    }
    this.getCaseIntroduce()
  },
  methods: {
    getCaseIntroduce(){
      this.$axios({
        method: "post",
        url: "/laf/laf/lafCharityProjectType/tableList.ajax",
        data: {
          pageSize: 15,
          pageNo: 1,
          orderCondition: 'asc',
          _entityName: 'org.aweto.laf.entity.LafCharityProjectType',
          condition: `projectClassify:${this.catagory}||`
        },
      })
      .then(res => {
        this.message =  res.rows[0]
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    async handleDownLoad(e){
      // 根据fileUrl获取附件信息
      const res = await this.$axios({
        method: "post",
        url: "/laf/attach/attachInfo/attachList.ajax",
        data: {
          fileId: e,
        },
      });
      console.log(res)
      const {
        localType,
        localAddress,
        username,
        password,
        filePath,
        saveName,
        fileName,
        fileType,
        id:infoNo,
      } = res[0];
      // 下载附件
      const res3 = await this.$axios({
        method: "post",
        url: "/laf/fileDownloadServlet",
        data: {
          localType,
          localAddress,
          username,
          password,
          filepath: filePath,
          saveName,
          fileName,
          fileType,
          pathType: "absolute",
          encode: "UTF-8",
          infoNo,
          viewMode:false
        },
        responseType: "blob",
      });
      downloadFileStream(res3, fileName);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  padding-bottom: 20px;
  border-top: none;
  .content-all {
    width: 100%;
    height: 23%;
    border: 1px solid #e5e5e5;
    border-top: 3px solid #cd242a;
    .title {
      font-size: 25px;
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center;
      color: #cd242a;
    }
  }
  .content-data {
    text-align: left;
    font-size:16px;
    width: 100%;
    min-height: 500px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    overflow-y: auto;
    padding: 20px 20px 20px 20px;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-all;
  }
  .content-data::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  .content-data::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e5e5e5;
  }
  .content-data::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #e5e5e5;
    border-radius: 0;
    background: #e5e5e5;
  }
  .footer {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid#e5e5e5;
    .next {
      float: left;
      margin-left: 30px;
      font-size: 12px;
    }
    .last {
      float: right;
      margin-right: 30px;
      font-size: 12px;
    }
  }
}
.detail {
  margin-top: 40px;
  margin-bottom: 40px;
}
.detail > div:first-child {
  margin-bottom: 15px;
  text-align: right;
}
.detail img {
  width: 100%;
  height: 300px;
}

.download {
  margin-left: 20px;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.download :nth-child(2) {
  margin-top: 10px;
  color: #dc2725;
  cursor: pointer;
}
</style>