<template>
  <div label="通知公告">
    <!-- <img class="image" src="@/assets/images/star3.png" alt="" /> -->
    <img class="image" src="@/assets/images/noticePoster.jpg" alt="" />
    <img class="image1" src="@/assets/images/star2.jpg" alt="" />
    <div class="home-container">
      <div class="line"></div>
      <div
        class="details"
        v-for="(item, index) in indexList"
        :key="index"
        @click="handleDetail(item)"
      >
        <div class="title">
          <span class="content">{{ item.announceTitle }}</span>
          <p class="date">{{ item.pubDtm }}</p>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
        class="paging"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handlePageChange"
        @prev-click="handlePageChange"
        @next-click="handlePageChange"
        :current-page.sync = "pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "",
  data() {
    return {
      // 通知公告
      indexList: [],
      pageNo: 1,
      pageSize: 10,
      condition: "|",
      total: 0,
      // fields:'announceTitle,state,pubDtm'
    };
  },
  created() {
    this.pageNo = parseInt(this.$route.params.page) || 1
  },
  watch: {
    '$route.params.page'(val) {
      this.pageNo = val
      this.handleGetList()
    }
  },
  mounted() {
    this.handleGetList();
  },
  methods: {
    ...mapActions(["setIndexDetail"]),
    // 列表接口
    handleGetList() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafAnnouncement/tableList.ajax",
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          condition: this.condition,
          orderField: "pubDtm",
          orderCondition: "desc",
          _entityName: "org.aweto.laf.entity.LafAnnouncement",
          // fields:this.fields
        },
      })
        .then((res) => {
          this.total = res.totalRows;
          this.indexList = res.rows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 当前页面
    handlePageChange(e) {
      this.$router.push({
        name: 'notice',
        params: {
          page: e
        }
      })
    },
    // 跳转详情
    handleDetail(data) {
      const pkValue = data.row_pkValue
      window.open(`./#/indexDetail?pkValue=${pkValue}`, '_blank')
    },
  },
};
</script>

<style lang='scss' scoped>
.title {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 20px;
  width: 95%;
}
.content{
  @include apply-small{
    display: inline-block;
    width: 350px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}
.date {
  float: right;
  font-size: 14px;
  text-align: center;
  color: #bbb;
}
.time {
  color: #bbb;
  font-size: 12px;
  float: right;
  margin-right: 10px;
}
.image {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: block;
}
.image1 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: none;
}
@include apply-small {
  .image1 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: block;
  }
  .image {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: none;
  }
}

.line {
  width: 100%;
  height: 1px;
  border-top: 1px solid #e5e5e5;
  margin-top: 38px;
  margin: 0 auto;
}
.details {
  width: 100%;
  height: 60px;
  overflow: hidden;
  height: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 auto;
  line-height: 60px;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
}
// .details :nth-child(2) {
//   float: right;
//   margin-right: 40px;
// }
.lafPage {
  text-align: center;
  margin-top: 50px;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #cd242a;
}
::v-deep .paging {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>