<template>
  <div class="mainContent">
    <h3 class="title">{{ structure.ourTitle }}</h3>
    <div class="link"></div>
    <p class="content" v-html="structure.ourContent"></p>
    <!-- <rich-content :content="structure.ourContent"></rich-content> -->
  </div>
</template>
<script>
export default {
  name: "structure",
  data() {
    return { structure: "" };
  },
  mounted() {
    this.getStructure();
  },
  methods: {
    getStructure() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafOur/find.ajax",
        data: {
          pkValue: "3",
          _entityName: "org.aweto.laf.entity.LafOur",
        },
      })
        .then((res) => {
          this.structure = res.recorder;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.mainContent {
  .title {
    text-align: center;
    font-size: 20px;
    color: #333333;
  }
  .link {
    margin-top: 20px;
    border-top: solid #e5e5e5 1px;
  }
  .content {
    margin: 15px;
  }
}
</style>