<template>
  <div>
    <div class="link"></div>
    <div v-for="(item, index) in workBriefing" :key="index">
      <div class="contentWrap" @click="handleDetail(item)">
        <!-- <img
          class="image"
          :src="newBaseurl + item.messageUrl"
          alt=""
          @error="handleNoImg"
        /> -->
        <div class="briefContent">
          <h3>{{ item.messageTitle }}</h3>
          <p>{{ item.messageDate }}</p>
          <p class="content">
            {{ item.messageSynopsis }}
          </p>
        </div>
      </div>
      <div class="link"></div>
    </div>
    <!-- 分页 -->
    <el-pagination
      class="paging"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="handlePageChange"
      @prev-click="handlePageChange"
      @next-click="handlePageChange"
      :current-page.sync = "pageNo"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      // 工作简报
      workBriefing: [],
      pageNo: 1,
      pageSize: 10,
      condition: "messageType:S_IN2||",
      total: 0,
    };
  },
  created() {
    this.pageNo = parseInt(this.$route.params.page) || 1
    this.handleGetList();
  },
  watch: {
    '$route.params.page'(val) {
      this.pageNo = val
      this.handleGetList()
    }
  },
  methods: {
    ...mapActions(["setWorkBriefingDetail"]),
    // 列表接口
    handleGetList() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafPublicMessage/tableList.ajax",
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          condition: this.condition,
          orderCondition: "desc",
          orderField: "messageDate",
        },
      })
        .then((res) => {
          this.total = res.totalRows;
          this.workBriefing = res.rows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
       // 当前页面
     handlePageChange(e) {
      this.$router.push({
        name: 'workBriefing',
        params: {
          page: e
        }
      })
    },
    // 跳转详情
    handleDetail(data) {
      console.log(data)
      const pkValue = data.row_pkValue
      window.open(`./#/workBriefingDetail?pkValue=${pkValue}`,'_blank')
    },
  },
};
</script>

<style lang='scss' scoped>
.contentWrap {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  // border-bottom: 1px solid #e5e5e5;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
  img {
    width: 220px;
    height: 130px;
    margin-right: 23px;
    flex: 0 0 auto;
    margin-left: 15px;
  }
  h3 {
    color: #333;
    font-size: 20px;
    margin-top: 10px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .content {
    font-size: 20px;
    margin-top: 10px;
    font-size: 16px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .briefContent {
    margin-left: 50px;
  }
  div {
    margin-bottom: 20px;
    color: #bbb;
  }
  p {
    margin-top: 10px;
    font-size: 14px;
    color: #666666;
    margin-right: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
.link {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}
.paging {
  text-align: center;
  padding-top: 40px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
</style>