<template>
  <div class="home-container">
    <!--公益项目  -->
    <div class="block-title-wrap PublicWelfare">
      <span class="block-title">公益项目</span>
      <el-row
        type="flex"
        class="tab-container"
        :justify="$smallScreen ? 'center' : 'end'"
      >
        <el-radio-group v-model="publicWelfareIndex">
          <el-radio-button
            v-for="(item, index) in publicWelfareTabs"
            @mouseover.native="handlePublicWelfareOver(item.label)"
            :class="active == index ? 'active' : ''"
            :label="item.label"
            @click.native="handleGoList(item.label)"
            :key="item.label"
            >{{ item.title }}</el-radio-button
          >
        </el-radio-group>
      </el-row>
      <div class="welfareContainer">
        <div>
          <el-row :gutter="60">
            <div
              v-for="(item, index) in publicWelfare"
              :key="index"
              @click="handleToDetail(item)"
            >
              <el-col :span="12" :xs="24">
                <div class="welfareItem">
                  <span class="DoT"></span>
                  <span class="welfareText"> {{ item.title }} </span>
                </div>
              </el-col>
            </div>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 公益捐赠总金额-->
    <el-row class="home-donation" :gutter="30">
      <el-col :span="13" :xs="24">
        <div class="donation-left">
          <h5 class="title">公益捐赠总金额(元)</h5>
          <div class="money">{{ totalMoney }}</div>
           <!-- <div class="money">100667300</div> -->
          <!-- 捐助单位轮播图 -->
          <!-- :autoplay="false"   停止自动轮播 -->
          <el-carousel
            :interval="5000"
            arrow="always"
            indicator-position="none"
            :height="$smallScreen ? '150px' : '220px'"
          >
            <el-carousel-item
              v-for="(page, pageIndex) in welfareSupport"
              :key="pageIndex"
            >
              <el-row>
                <el-col
                  :span="8"
                  v-for="(item, index) in page"
                  :key="index"
                  class="donation-item"
                >
                  <img :src="newBaseurl + item.companyLogo" class="welfImg" />
                </el-col>
              </el-row>
            </el-carousel-item>
          </el-carousel>
          <div class="btn-wrap">
            <el-button @click="toEnterprise">公益支持</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="11" :xs="24">
        <div class="donation-right">
          <el-tabs v-model="activeName">
            <el-tab-pane label="捐赠爱心榜" name="first">
              <ul class="donation-list">
                <vue-seamless-scroll
                  :data="donationInfo"
                  :class-option="classOption"
                  class="seamless-warp"
                >
                  <ul class="item">
                    <li
                      v-for="(item, index) in donationInfo"
                      :key="index"
                      class="donationText"
                    >
                      <span>{{
                        item.payAnonymity === "是" ? "匿名" : item.payName
                      }}</span
                      ><span
                        class="number"
                        v-text="item.payMoney + '元'"
                      ></span>
                    </li>
                  </ul>
                </vue-seamless-scroll>
              </ul>
              <div class="welfBtn">
                <el-button @click="toIndividual">查看更多</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="捐赠者查询" name="second">
              <el-form :model="ruleForm" ref="ruleForm" label-width="5px">
                <el-form-item
                  prop="keyword"
                  :rules="[
                    {
                      required: true,
                      message: '请输入捐赠者姓名',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="ruleForm.keyword"
                    placeholder="请输入捐赠者姓名"
                  >
                    <img
                      src="@/assets/images/searchIcon.png"
                      alt=""
                      slot="suffix"
                      class="searchIcon"
                      @click="handleSearch"
                    />
                  </el-input>
                </el-form-item>
              </el-form>

              <ul class="welfList">
                <li v-for="item in donors" :key="item">
                  <div class="searchList">
                    <div class="searchItem">
                      <span>捐赠者姓名：</span>
                      <span>{{
                        item.payAnonymity === "是" ? "匿名" : item.payName
                      }}</span>
                    </div>
                    <div class="searchItem">
                      <span>捐赠金额：</span>
                      <span>{{ item.payMoney }}</span>
                    </div>
                    <div class="searchItem">
                      <span>支付方式：</span>
                      <span>{{ item.payType }}</span>
                    </div>
                    <div class="searchItem">
                      <span>捐赠时间：</span>
                      <span>{{ item.payTime }}</span>
                    </div>
                  </div>
                </li>

                <!-- <div class="loading" v-if="isLoading">
                  <span>数据加载中...</span>
                </div> -->
                <div class="noMatch" v-if="showNoMatch">
                  <span>没有搜索到匹配结果</span>
                </div>
              </ul>

              <el-pagination
                class="fenYe"
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :current-page="pageIndex"
                @current-change="handlePageChange"
              >
              </el-pagination>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
// import { throttle } from "lodash";
// import onlineService from "@/components/onlineService";
import { mapActions } from "vuex";
import { chunk } from "loadsh";
export default {
  data() {
    return {
      formData: {
        info: "",
      },
      innerVisible: false,
      active: 0,
      donationInfo: [],
      publicWelfareIndex: 0,
      newsActiveIndex: 0,
      currentNews: {},
      showNoMatch: false,
      // isLoading: false,
      // 公益项目
      publicWelfareTabs: [
        {
          label: 0,
          title: "法援案件",
          com: "case",
        },
        { label: 1, title: "普法宣传" },
        { label: 2, title: "特色站点" },
        { label: 3, title: "高校法援" },
        { label: 4, title: "培训奖励" },
      ],
      // 捐赠金额
      totalMoney: "",
      // 公益项目列表
      publicWelfare: [],
      // 公益支持 轮播图
      welfareSupport: [],
      activeName: "first",
      // keyword: "",
      donors: [],
      pageIndex: 1,
      pageSize: 1,
      total: 0,
      ruleForm: {
        keyword: "",
      },

      classOption: {
        autoPlay: false,
        step: 0.5,
      },
    };
  },

  mounted() {
    // 公益项目
    this.getPublicWelfare(0);
    this.lafCompany();
    this.lafDonation();
    this.donationMoney();

    setTimeout(() => {
      this.classOption.autoPlay = true;
    }, 9000);
  },
  methods: {
    // ...mapActions(["setCaseDetail"]),
    // // 不要删代码有用
    // handleToDetail(e) {
    //   // 将详情赋值给vuex中
    //   this.setCaseDetail(e);
    //   this.$router.push({
    //     name: "caseDetail",
    //   });
    // },
    handleToDetail(data) {
      const row_pkValue = data.row_pkValue;
      window.open(`./#caseDetail?row_pkValue=${row_pkValue}`, "_blank");
    },

    handleGoList(label) {
      if (!this.$smallScreen) {
        let path;
        if (label === 0) {
          path = "/welfareProject/S_IN0";
        } else if (label === 1) {
          path = "/welfareProject/S_IN1";
        } else if (label === 2) {
          path = "/welfareProject/S_IN2";
        } else if (label === 3) {
          path = "/welfareProject/S_IN3";
        } else if (label === 4) {
          path = "/welfareProject/S_IN4";
        }
        this.$router.push(path);
      } else {
        // 如果是小屏则切换tab页
        this.handlePublicWelfareOver(label);
      }
    },

    handleNews(item, index) {
      this.newsActiveIndex = index;
      this.currentNews = item;
    },
    handleClick(e) {
      this.innerVisible = true;
      this.formData = e;
    },
    getfunc(index, com) {
      // debugger
      this.active = index;
      this.$router.push(com);
    },
    //  公益项目鼠标移入
    handlePublicWelfareOver(label) {
      this.publicWelfareIndex = label;
      this.getPublicWelfare(label);
    },

    // 公益项目接口
    getPublicWelfare(id) {
      this.$axios
        .post("/laf/laf/lafCharityProject/tableList.ajax", {
          pageNo: "1",
          pageSize: "10",
          condition: `projectClassify:S_IN${id}||`,
          orderCondition: "desc",
          orderField: "publishTime",
        })
        .then((res) => {
          this.publicWelfare = res.rows.slice(0, 8);
        })
        .catch(function (error) {});
    },
    // 捐赠总金额
    donationMoney() {
      this.$axios
        .get("/laf/laf/lafPay/getSumMoney.ajax", {
          access_token: "NY_SOEyxfX7RG6Oa2uXRlEeoMWpxgULe",
        })
        .then((res) => {
          this.totalMoney = res.tato;
        })
        .catch(function (error) {});
    },

    // 公益公司轮播接口
    lafCompany() {
      this.$axios
        .post("/laf/laf/lafCompany/tableList.ajax", {
          pageSize: "100",
          pageNo: "1",
          condition: "-1",
        })
        .then((response) => {
          this.welfareSupport = chunk(response.rows, 9);
        })
        .catch(function (error) {});
    },

    // 捐赠爱心榜接口
    lafDonation() {
      this.$axios
        .post("/laf/laf/lafPay/tableList.ajax", {
          pageSize: "100",
          pageNo: "1",
          orderField: "payTime",
          orderCondition: "desc", //捐赠金额倒序
          // condition: "payMoney>0.01|payMoney<0.06||",
          // payState: "S_IN0||",
          condition: "payState:S_IN0||",
        })
        .then((res) => {
          this.donationInfo = res.rows;
        })
        .catch(function (error) {});
    },

    // 捐赠者查询接口
    handleSearch() {
      // this.isLoading = true;
      this.showNoMatch = false;

      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return false;
        this.$axios
          .post("/laf/laf/lafPay/tableList.ajax", {
            pageNo: this.pageIndex,
            pageSize: this.pageSize,
            orderField: "payMoney",
            orderCondition: "desc", //捐赠金额倒序
            // condition: "payMoney>0.01|payMoney<0.06||",
            condition: `payName:@${this.ruleForm.keyword}|payState:S_IN0||`,
          })
          .then((res) => {
            this.donors = res.rows;
            this.total = res.totalRows;
            // this.isLoading = false;
            if (this.donors.length) {
              this.showNoMatch = false;
            } else {
              this.showNoMatch = true;
            }
          })
          .catch(function (error) {});
      });
    },

    submitForm(formKeyWord) {
      this.$refs[formKeyWord].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formKeyWord) {
      this.$refs[formKeyWord].resetFields();
    },

    // 点击公益支持跳转到爱心企业
    toEnterprise() {
      this.$router.push("/donation/enterprise");
    },

    // 点击查看更多跳转到爱心个人
    toIndividual() {
      this.$router.push("/donation/individual");
    },

    handlePageChange(index) {
      this.pageIndex = index;
      this.handleSearch();
    },

    // throttle 防抖节流 的处理
    // handleScroll: throttle(function (e) {
    //   const ele = document.documentElement || document.body;
    //   // const eleHeight = ele.scrollHeight;
    //   let toTop = ele.scrollTop || window.pageYOffset;
    //   if (toTop > 275 && toTop < document.body.scrollHeight - 458) {
    //     this.visible = true;
    //   } else {
    //     this.visible = false;
    //   }
    // }, 100),
  },

  // 组件销毁前  解除事件(该事件注册在全局)，防止继续触发该事件
  // beforeDestroy() {
  //   document.removeEventListener("scroll", this.handleScroll);
  // },
};
</script>

<style lang='scss' scoped>
// 公益项目导航
.PublicWelfare {
  .tab-container {
    @include apply-small {
      margin-top: 20px;
    }
  }
  ::v-deep .el-radio-group {
    float: right;
  }
  ::v-deep
    .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    // background-color: #c81b38;
    background-color: #dc2725 !important;
    box-shadow: -1px 0px 9px 0px #222222 !important;
    border-color: #ccc !important;
  }
  ::v-deep .el-radio-button__inner {
    width: 170px;
    height: 40px;
    line-height: 15px;
    font-size: 18px;
    // margin-bottom: 20px;
    @include apply-small {
      font-size: 12px;
      width: 100px;
    }
    // color: #333333;
  }
  .welfareContainer {
    margin-top: 30px;
    .welfareItem {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px dashed #d8d8d8;

      .DoT {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #eee;
      }
      .welfareText {
        width: 500px;
        font-size: 16px;
        margin-left: 20px;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: $red;
        }
      }
    }
  }
}

// 历史捐赠
.home-donation {
  margin-top: -11px;
  // margin-top: 80px;
  .btn-wrap {
    // @include apply-small {
    //   margin-top: 20px;
    // }
    margin-top: 35px;
    text-align: center;
    .el-button.el-button--default {
      width: 210px;
      height: 37px;
      line-height: 10px;
      background-color: #dc2725;
      font-size: 16px;
      color: #ffffff;
    }
  }
  // 捐助单位轮播图
  .donation-left {
    position: relative;
    height: 545px;
    padding: 40px 10px 17px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    .title {
      color: #333333;
      font-size: 24px;
      margin-left: 8px;
    }
    .money {
      font-size: 42px;
      color: #dc2725;
      margin-top: 25px;
      margin-left: 8px;
      margin-bottom: 45px;
      height: 45px;
    }
    //  历史捐赠轮播图
    ::v-deep .el-carousel__container {
      .el-carousel__arrow {
        width: 18px;
        height: 18px;
        line-height: 15px;
        background-color: #fff;
        color: #bbb;
        border: 1px solid;
      }
      .el-carousel__arrow--left {
        left: -0.5px;
      }

      .el-carousel__arrow--right {
        right: 0.5px;
      }
      .donation-item {
        text-align: center;
        margin-bottom: 10px;
        img {
          width: 90%;
          height: 60px;
          object-fit: contain;
        }
      }
    }
  }
  .donation-right {
    height: 545px;
    border: 1px solid #e6e6e6;
    padding: 25px 20px 39px;
    @include apply-small {
      margin-top: 40px;
    }
    .donation-list {
      margin: 13px 22px;
      line-height: 34px;
      font-size: 18px;
      color: #333;
      .number {
        float: right;
        color: #dc2725;
      }
    }
    ::v-deep .el-tabs__item {
      color: #333333;
      font-size: 18px;
    }
    ::v-deep .el-tabs__active-bar {
      height: 3px;
      background-color: #ca4142;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      content: none;
    }
    ::v-deep .el-tabs__item:hover {
      color: #dc2725;
    }
    ::v-deep .el-tabs__item.is-active {
      color: $red;
    }
    ::v-deep .el-tabs__nav-scroll {
      margin-bottom: 15px;
    }
  }
  .welfBtn {
    margin-top: 40px;
    text-align: center;
    .el-button.el-button--default {
      width: 210px;
      height: 37px;
      line-height: 10px;
      background-color: #dc2725;
      font-size: 16px;
      color: #ffffff;
    }
  }

  .seamless-warp {
    height: 300px;
    overflow: hidden;
  }
  .donationText {
    cursor: pointer;
    &:hover {
      color: $red;
    }
  }
  ::v-deep.el-input--suffix .el-input__inner {
    // cursor: pointer;
    border-color: #dc2725;
  }
  .searchList {
    margin-top: 30px;
  }
  ::v-deep .el-input__suffix {
    top: 6px;
    margin-right: 20px;
  }
  .searchItem {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
    &:hover {
      background-color: #f8f8f8;
    }
  }
  .searchItem :nth-child(1) {
    margin-left: 20px;
  }
}
.searchIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
.welfList {
  height: 300px;
  overflow: hidden;
}
.fenYe {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 20px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}

.noMatch {
  padding-top: 30px;
  text-align: center;
  color: #999;
  font-size: 20px;
}
// .loading {
//   padding-top: 30px;
//   text-align: center;
//   color: #999;
//   font-size: 20px;
// }
</style>