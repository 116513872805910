<template>
  <div label="我要开票">
    <div class="donationContainer">
      <div class="title-content">
        <h3 class="title">基金会官网捐赠开票方式</h3>
      </div>
      <h3 class="invoice-detail-content">
        <span class="detail">1. </span>请您将相关信息发至邮箱<span class="mark"
          >jslaf2007@163.com</span
        >，邮件标题请填写<span class="mark">"捐赠开票"</span>，信息内容包括<span
          class="mark"
          >微信</span
        >或<span class="mark">支付宝</span
        ><span class="mark">商户单号</span>、<span class="mark">票据抬头</span
        >、<span class="mark">收件人姓名</span>、<span class="mark"
          >联系方式</span
        >、<span class="mark">邮寄地址</span>。以上<span class="mark"
          >信息务必正确</span
        >，否则<span class="mark">影响开票</span>与<span class="mark">送达</span
        >。
      </h3>
      <h3 class="invoice-detail-content">
        <span class="detail">2. </span> 基于捐赠成本考虑，捐赠<span class="mark"
          >100元以下</span
        >的票据邮寄费用由捐赠人<span class="mark">自付</span>。
      </h3>
      <h3 class="invoice-detail-content">
        <span class="detail">3. </span>商户单号查询方式：
      </h3>
      <h3 class="invoice-detail-content invoice-one">
        ①若使用<span class="mark">微信捐赠</span>，您可以在<span class="mark"
          >“微信-我-支付-钱包-账单”</span
        >中找到这笔捐赠，查看<span class="mark">商户单号</span>。
      </h3>
      <h3 class="invoice-one">
        ②若使用<span class="mark">支付宝捐赠</span>，您可以在<span class="mark"
          >“支付宝-我的-账单”</span
        >中找到这笔捐赠，查看<span class="mark">商户单号</span>。
      </h3>
      <h3 class="invoice-detail-content">
        <span class="detail">4. </span>按照财务制度相关规定，<span class="mark"
          >跨年发票不能申请</span
        >，<span class="mark">发票申请请在当年内完成</span>。
      </h3>

      <!-- <div class="donationTop">
        <h2>银行汇款</h2>
        <div class="accountsInfo">
          <span>用&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户</span>
          <span>江苏省法律援助基金会</span>
        </div>
        <div class="accountsInfo">
          <span>开户银行</span>
          <span>中国农业银行云南路支行</span>
        </div>
        <div class="accountsInfo">
          <span>账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</span>
          <span>10-100501040005783</span>
        </div>
      </div>

      <h2>邮局汇款</h2>
      <div class="accountsInfo">
        <span>单位名称</span>
        <span>江苏省法律援助基金会</span>
      </div>
      <div class="accountsInfo">
        <span>单位地址</span>
        <span>南京市北京西路28号</span>
      </div>
      <div class="accountsInfo">
        <span>邮政编码</span>
        <span>210024</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.donationContainer {
  .title-content {
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    .title {
      font-weight: bold;
    }
  }

  .invoice-detail-content {
    margin-top: 18px;
    font-size: 18px;
    .detail {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .invoice-one {
    font-size: 18px;
    margin-left: 20px;
  }
  .mark {
    color: red;
  }
}
h2 {
  color: #c0504d;
  margin-bottom: 15px;
  font-size: 22px;
}
.donationTop {
  margin-bottom: 90px;
}

.accountsInfo {
  margin-bottom: 15px;
}
.accountsInfo :nth-child(1) {
  margin-right: 30px;
}
</style>