<template>
  <div class="main-wrap">
    <!-- <page-bread /> -->
    <el-container class="home-container">
      <el-aside width="292px">
        <page-aside>
          <el-menu :default-active="activeIndex" router>
            <el-menu-item
              :index="item.path"
              v-for="item in menuList"
              :key="item.path"
              >{{ item.name }}</el-menu-item
            >
          </el-menu>
        </page-aside>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import pageAside from "@/components/pageAside.vue";
export default {
  name: "",
  data() {
    return {
      activeIndex: this.$route.path,
      menuList: [
        {
          name: "视频库",
          path: "/mediaLib/videoLib",
        },
        {
          name: "图片库",
          path: "/mediaLib/photoLib",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  components: {
    pageAside,
  },
  watch: {
    "$route.path"(val) {
      this.activeIndex = val;
    },
  },
};
</script>

<style lang='scss' scoped>
.el-main {
  border-left: 8px solid #f4f4f4;
}
</style>