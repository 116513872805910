<template>
  <div>
    <h3 class="title">{{ constitution.ourTitle }}</h3>
    <div class="link"></div>
    <p class="content" v-html="constitution.ourContent"></p>
    <div class="download" v-if="constitution.ourFile">
      <div>附件下载：</div>
      <div @click="handleDownLoad">{{ constitution.ourTitle }}</div>
      <!-- <a href="javascript:;" @click="handleDownLoad">{{
        constitution.ourTitle
      }}</a> -->
    </div>
  </div>
</template>

<script>
import { downloadFileStream } from "@/utils/tools";
export default {
  name: "constitution",
  data() {
    return {
      constitution: "",
    };
  },
  mounted() {
    this.getConstitution();
  },
  methods: {
    getConstitution() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafOur/find.ajax",
        data: {
          pkValue: "2",
          _entityName: "org.aweto.laf.entity.LafOur",
          // fileId: res1.recorder.ourFile,
        },
      })
        .then((res) => {
          this.constitution = res.recorder;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async handleDownLoad() {
      const res1 = await this.$axios({
        method: "post",
        url: "/laf/laf/lafOur/find.ajax",
        data: {
          pkValue: "2",
          _entityName: "org.aweto.laf.entity.LafOur",
        },
      });

      // 根据fileUrl获取附件信息
      const res2 = await this.$axios({
        method: "post",
        url: "/laf/attach/attachInfo/attachList.ajax",
        data: {
          fileId: res1.recorder.ourFile,
        },
      });
      const {
        localType,
        localAddress,
        username,
        password,
        filePath,
        saveName,
        fileName,
        fileType,
        id:infoNo
      } = res2[0];

      // 下载附件
      const res3 = await this.$axios({
        method: "post",
        url: "/laf/fileDownloadServlet",
        data: {
          localType,
          localAddress,
          username,
          password,
          filepath: filePath,
          saveName,
          fileName,
          fileType,
          pathType: "absolute",
          encode: "UTF-8",
          infoNo,
          viewMode:false
        },
        responseType: "blob",
      });
      downloadFileStream(res3, fileName);
    },
  },
};
</script>

<style lang='scss' scoped>
.title {
  text-align: center;
  font-size: 20px;
  color: #333333;
}
.link {
  margin-top: 20px;
  border-top: solid #e5e5e5 1px;
}
.content {
  margin: 15px;
}
.download {
  margin-left: 20px;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.download :nth-child(2) {
  font-size: 14px;
  margin-top: 10px;
  color: #dc2725;
  cursor: pointer;
  margin-left: 32px;
  &:hover {
    text-decoration: underline;
  }
}
</style>