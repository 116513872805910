<template>
  <div class="home-container">
    <!--典型案例  -->
    <div class="block-title-wrap">
      <div>
        <div class="block-title">典型案例</div>
        <div class="caseBtn" @click="toCaseList">更多&nbsp;>></div>
      </div>
      <div class="classicCase">
        <el-row :gutter="60">
          <div
            v-for="(item, index) in typicalCase"
            :key="index"
            @click="handleDetail(item)"
          >
            <el-col :span="12" :xs="24">
              <div class="classicCaseItem">
                <span class="DoT"></span>
                <span class="classicCaseText">{{ item.title }} </span>
              </div>
            </el-col>
          </div>
        </el-row>
      </div>
    </div>
    <!-- <classicCase  :visible.sync = "innerVisible" @close="innerVisible=false" :formData="formData" /> -->
  </div>
</template>

<script>
import {mapActions} from 'vuex'
// import classicCase from '@/components/classicCase'
export default {
  data() {
    return {
      formData: {
        info: "",
      },
      innerVisible: false,
      // 典型案例
      typicalCase: [
        {
          title: "",
        },
        { publishTime: "" },
      ],
    };
  },
  mounted() {
    this.lafCase();
  },
  methods: {
    ...mapActions(["setClassicHomeDetail"]),
    // 点击显示详情
    handleDetail(data) {
      // console.log(data)
      const pkValue = data.row_pkValue;
      window.open(`./#/classicHomeDetail?pkValue=${pkValue}`,'_blank')
      // const pkValue = data.row_pkValue;
      // window.open(`./#/classicHomeDetail?pkValue=${pkValue}`, '_blank')
      // console.log(data)
      // 将详情赋值给vuex中
      // this.setClassicHomeDetail(e);
      // this.$router.push({
      //   name: "classicHomeDetail",
      // });
      // this.innerVisible = true
      // this.formData = e
    },
    // 典型案例
    lafCase() {
      this.$axios
        .post("/laf/laf/lafTypicalCase/tableList.ajax", {
          pageSize: "8",
          pageNo: "1",
          condition: "-1",
          orderCondition: "desc",
          orderField: "publishTime",
        })
        .then((response) => {
          this.typicalCase = response.rows.slice(0.8);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    toCaseList() {
      this.$router.push("/typicalCase");
    },
  },
  components: {
    // classicCase
  },
};
</script>

<style lang='scss' scoped>
.caseBtn {
  float: right;
  width: 100px;
  line-height: 40px;
  // border-bottom: 2px solid #dc2725;
  font-size: 16px;
  cursor: pointer;
  color: #999;
  text-align: center;
  &:hover {
    color: #dc2725;
    // border-bottom: 2px solid #dc2725;
  }
}

.classicCase {
  margin-top: 30px;
  .classicCaseItem {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    border-bottom: 1px dashed #d8d8d8;

    .DoT {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #eee;
    }
    .classicCaseText {
      width: 500px;
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: $red;
      }
    }
  }
}
</style>