
<template>
  <div label="文件下载">
    <div class="home-header FileDownload">
    <el-form :model="queryForm" label-position="right" size="medium" label-width="110px" class="file-name">
      <el-form-item  prop="fileName" class="file">
        <el-row>
          <el-col :span="17">
            <el-input v-model="queryForm.fileName" placeholder="请输入文件名称"></el-input>
          </el-col>
          <el-col :span="5"  class="btn">
            <el-button type="primary" @click="handleNewSearch" class="btn-search">查询</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
      <!-- 表格部分 -->
      <div class="formContent">
        <el-table :data="tableData" stripe>
          <el-table-column type="index" label="序号" width="170">
          </el-table-column>
          <el-table-column prop="fileName" label="文件名称" width="170">
          </el-table-column>
          <el-table-column prop="fileDate" label="附件上传时间" width="170">
          </el-table-column>
          <el-table-column prop="fileType" label="附件类型" width="170">
          </el-table-column>
          <el-table-column label="操作" width="170">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleDown(scope.row)"
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-pagination
        class="paging"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrent"
        @prev-click="handlePrev"
        @next-click="handleNext"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { downloadFileStream, downloadFile } from "@/utils/tools";
export default {
  data () {
    return {
      queryItems: null,
      queryForm: {
        offset: 0,
        orderField: 'fileDate',
        orderCondition: 'asc',
        fastCondition: '',
        _entityName: 'org.aweto.laf.entity.LafFileDownload',
        condition: "|",
        fileName: ''
      },
      pageNo: 1,
      pageSize: 10,
   
      total: 0,
      tableData: [],
    }
  },
  created () {
    this.queryItems = { ...this.queryForm }
    this.handleGetList();
  },
  methods: {
    // 列表
    handleGetList () {
      this.queryItems.condition = `fileName:@${this.queryForm.fileName}||`
      this.$axios({
        method: "post",
        url: "/laf/laf/lafFileDownload/tableList.ajax",
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          ...this.queryItems
        }
      })
        .then((res) => {
          this.total = res.totalRows;
          this.tableData = res.rows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 搜索
    handleNewSearch () {
      this.pageNo = 1
      this.handleGetList()
    },
    //当前页
    handleCurrent (e) {
      this.pageNo = e;
      this.handleGetList();
    },
    // 上一页
    handlePrev (e) {
      this.pageNo = e;
      this.handleGetList();
    },
    // 下一页
    handleNext (e) {
      this.pageNo = e;
      this.handleGetList();
    },
    // 下载
    handleDown (e) {
      // console.log(e)
      this.$axios({
        method: "post",
        url: "/laf/attach/attachInfo/attachList.ajax",
        data: {
          fileId: e.fileUrl,
        },
      })
        .then((res) => {
          if (res && res.length) {
            let d = res[0];
            let localType = d.localType;
            let localAddress = d.localAddress;
            let username = d.username;
            let password = d.password;
            let filepath = "LafFileDownload/";
            let saveName = d.saveName;
            let filename = d.fileName;
            // let fileType = d.fileType;
            let fileType = '';
            let pathType = "absolute";
            let encode = "UTF-8";
            const infoNo = d.id
            this.$axios({
              method: "post",
              url: "/laf/fileDownloadServlet",
              params: {
                localType,
                localAddress,
                username,
                password,
                filepath,
                saveName,
                filename,
                fileType,
                pathType,
                encode,
                infoNo
              },
              responseType: "blob",
            })
              .then((resp) => {
                downloadFileStream(resp, d.fileName);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cell {
  text-align: center;
}

.formContent {
  width: 100%;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.FileDownload {
  width: 100%;
  border: 1px solid #f5f5f5;
}
::v-deep .el-table thead {
  color: #606266;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #cd242a;
}

.el-table {
  font-size: 16px;
}
.el-table td,
.el-table th {
  text-align: center;
}
.paging {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.formPage {
  height: 68px;
  line-height: 68px;
  font-size: 16px;
  color: #404040;
  border: 1px solid #eee;
  padding: 0 15px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
background-color: #cd242a;
}


.btn-search{
    margin-left:20px;
    background: #cd242a;
    border-color: #cd242a;
}
.paging {
    // margin-left:220px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.file-name{
    float: right;
    margin-right:80px;
    margin-top:20px;
    margin-bottom:10px;
    // margin-left:20px;
}

</style>