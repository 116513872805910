<template>
  <div class="aboutUs">
    <div class="title">联系我们</div>
    <div class="link"></div>
    <!-- <el-row :gutter="20" class="footer-content">
      <el-col :span="10" class="footerItem">
        <p>江苏省法律援助基金会</p>
        <p>单位地址：江苏省南京市北京西路28号</p>
        <p>联系电话：025—83591353/1357</p>
        <p>电子邮箱：jslaf2007@163.com</p>
        <p>邮政编码：210024</p>
      </el-col>
      <el-col :span="14" class="pic">
        <img class="img" src="@/assets/images/map.jpg" alt="" />
      </el-col>
    </el-row> -->

    <div class="footer-content">
      <div class="footerItem">
        <p>江苏省法律援助基金会</p>
        <p>单位地址：江苏省南京市北京西路28号</p>
        <p>联系电话：025-83591353</p>
        <p>电子邮箱：jslaf2007@163.com</p>
        <p>邮政编码：210024</p>
      </div>
      <!-- <div class="pic"> -->
      <img
        class="img"
        src="@/assets/images/map.jpg"
        alt=""
        @error="handleNoImg('big')"
      />
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.aboutUs {
  overflow: hidden;
  .title {
    text-align: center;
    font-size: 20px;
    color: #333333;
    margin-bottom: 23px;
  }
  .link {
    border-bottom: 1px solid #e5e5e5;
  }
  ::v-deep .el-main {
    padding: 0;
  }
  .footer-content {
    display: flex;
    margin-top: 25px;
    overflow: hidden;
    @include apply-small {
      padding-top: 40px;
    }
  }
  .footerItem {
    width: 100%;
    font-size: 16px;
    margin-left: 20px;
    padding-top: 33px;
    @include apply-small {
      margin-left: 10px;
      padding-top: 0;
    }
  }

  .img {
    width: 500px;
    height: 450px;
    margin-left: 10px;
    flex: 0 0 auto;
    @include apply-small {
      width: 180px;
      height: 240px;
      margin-left: 10px;
      flex: 0 0 auto;
    }
  }

  p {
    margin-bottom: 20px;
  }
}
</style>