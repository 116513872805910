<template>
  <div class="inidividual-container">
    <el-row class="individual-header">
      <el-col :span="6">时间 </el-col>
      <el-col :span="6">金额</el-col>
      <el-col :span="6">捐赠人</el-col>
      <el-col :span="6">支付方式</el-col>
    </el-row>
    <div class="link"></div>
    <el-row class="individual-row" v-for="(item, index) in list" :key="index">
      <el-col :span="6">{{ item.payTime }} </el-col>
      <el-col :span="6">{{ item.payMoney }}</el-col>
      <el-col :span="6">{{
        item.payAnonymity === "是" ? "匿名" : item.payName
      }}</el-col>
      <el-col :span="6">{{ item.payType }}</el-col>
    </el-row>
    <!-- 分页 -->
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      pageIndex: 1,
      pageSize: 30,
      total: 0,
      list: [],
    };
  },
  mounted() {
    this.getIndividual();
  },
  methods: {
    getIndividual() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafPay/tableList.ajax",
        data: {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          orderField: "payTime",
          orderCondition: "desc",
          condition: "payState:S_IN0|payPeopleType:S_IN0||",
        },
      })
        .then((res) => {
          this.list = res.rows;
          this.total = res.totalRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handlePageChange(index) {
      this.pageIndex = index;
      this.getIndividual();
    },
  },
};
</script>

<style lang='scss' scoped>
.individual-header {
  font-size: 16px;
  color: #c40000;
  ::v-deep .el-col {
    text-align: center;
  }
}
.link {
  height: 1px;
  margin-top: 9px;
  margin-bottom: 21px;
  border-bottom: 1px solid #cd242a;
}
.individual-row {
  margin-bottom: 15px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
  ::v-deep .el-col {
    text-align: center;
    height: 100%;
  }
}
.fenYe {
  text-align: center;
  margin-top: 30px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
</style>