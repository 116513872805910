<style lang="scss" scoped>
.menu-wrapper {
  height: 50px;
  display: flex;
  align-items: center;
  @include apply-small {
    display: none;
  }
  .nav-menu {
    flex: 1;
    text-align: center;
    position: relative;
    height: 100%;
    .menu-title {
      height: 100%;
      line-height: 50px;
      display: inline-block;
      font-size: 22px;
      color: #333;
      font-family: MicrosoftYaHei;
      cursor: pointer;
    }
    &:hover .menu-title {
      height: 46px;
      border-bottom: 4px solid #c81b38;
    }
    &:hover .menu-list {
      display: block;
    }
    .menu-list {
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      z-index: 10;
      background-color: #ffffff;
      box-shadow: 1px 1px 18px 0px rgba(0, 0, 0, 0.16);
      padding: 10px 10px;
      .menu-item {
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
      }
      .menu-item:hover {
        color: #c81b38;
      }
    }
  }
}
.active-menu-item {
  color: #c81b38;
}
.active-menu-title {
  height: 46px !important;
  border-bottom: 4px solid #c81b38;
}
</style>

<template>
  <div class="home-container menu-wrapper">
    <div
      v-for="item in menuList"
      @click="handleMenuClick(item)"
      :key="item.naviId"
      class="nav-menu"
    >
      <span
        class="menu-title"
        :class="getRoute.includes(item.naviPath) || getActiveTitle.includes(item.naviPath) || getCurrentRoute.includes(item.naviPath)? 'active-menu-title' : ''"
      >
        {{ item.naviName }}
      </span>
      <ul v-if="item.childrens.length > 0" class="menu-list">
        <li
          @click="handleSubMenuClick(subItem)"
          v-for="subItem in item.childrens"
          :key="subItem.naviId"
          class="menu-item"
          :class="activeNo == subItem.naviNo ? 'active-menu-item' : ''"
        >
          {{ subItem.naviName }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      default: () => [],
    },
  },
  computed: {
    getRoute: function(){
      return this.$store.state.navBar.storeRoute
    },
    getActiveTitle: function(){
      return this.$store.state.navBar.activeTitle
    },
    getCurrentRoute: function(){
      return this.$store.state.navBar.currentRoute
    }
  },
  created(){
    // 仅用于刷新页面
    this.$store.dispatch('setCurrentRoute',location.hash)
  },
  data() {
    return {
      activeNo: 0
    };
  },
  methods: {
    handleMenuClick(item) {
      if (item.childrens && item.childrens.length > 0) return;
      this.$emit("on-menu", item);
      this.$store.dispatch('setCurrentRoute','')
      this.$store.dispatch('setActiveTitle',item.naviPath)
      this.$store.dispatch('setRoute',item.naviPath)
      this.activeNo = ''
    },
    handleSubMenuClick(item) {
      event.stopPropagation()
      this.activeNo = item.naviNo;
      if (item.childrens && item.childrens.length > 0) return;
      this.$emit("on-menu", item);
      
      // 点击子菜单，父菜单也要变
      this.$store.dispatch('setCurrentRoute','')
      // 子菜单的路径中包含父菜单的路径
      this.$store.dispatch('setActiveTitle',item.naviPath)
      console.log(item.naviPath)
      this.$store.dispatch('setRoute',item.naviPath)
    },
  },
};
</script>