<template>
  <div>
    <h3>图片库</h3>
    <div class="link"><div class="linkTwo"></div></div>
    <el-row class="content" :gutter="20">
      <el-col :span="8" v-for="(item, index) in photoLib" :key="index">
        <div class="photoContent">
          <el-image
            v-if="item.imagePath"
            class="img"
            :src="newBaseurl + item.imagePath"
            :preview-src-list="
              photoLib.map((item) => newBaseurl + item.imagePath)
            "
            @error="handleNoImg"
          >
          </el-image>
          <!-- <h4 class="title">{{ item.imageTitle }}</h4> -->
        </div>
      </el-col>
    </el-row>
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      photoLib: [],
      pageIndex: 1,
      pageSize: 12,
      total: 0,
    };
  },
  mounted() {
    this.lafPhotoImg();
  },
  methods: {
    lafPhotoImg() {
      this.$axios
        .post("/laf/laf/lafMedia/tableList.ajax", {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          condition: "mediaClassify:S_IN1||",
        })
        .then((response) => {
          this.photoLib = response.rows;
          this.total = response.totalRows;
        })
        .catch(function (error) {});
    },

    handlePageChange(index) {
      this.pageIndex = index;
      this.lafPhotoImg();
    },
  },
};
</script>

<style lang='scss' scoped>
h3 {
  font-size: 16px;
  margin-left: 15px;
}
.link {
  margin: 25px 0;
  border-bottom: 1px solid #999999;
}
.linkTwo {
  width: 80px;
  height: 2px;
  margin-bottom: -1px;
  background: #e60620;
}
.content {
  padding-left: 30px;
  padding-right: 30px;

  .photoContent {
    position: relative;
    width: 100%;
    margin-bottom: 26px;
  }
  .img {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 217px;
  }
  .title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    background: rgba($color: #000000, $alpha: 0.4);
  }
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}

::v-deep.el-tabs__header {
  margin: 0;
}
.fenYe {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>