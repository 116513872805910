<template>
  <div id="app">
    <page-header></page-header>
    <!-- 头部搜索框 -->
    <div class="home-container search-wrap">
      <el-row :gutter="24">
        <el-col :span="12" :xs="24">
          <img src="@/assets/images/newlogin.png" class="login" />
          <small-nav-bar
            class="small-nav-bar"
            :menuList="menuList"
            @on-menu="handleMenuClick"
          />
        </el-col>
        <el-col :span="12" :xs="24" class="login-search">
          <el-input v-model="queryForm.lafNewsTitle" placeholder="请输入您要查询的内容" class="input" @keyup.enter.native="search"></el-input>
          <div class="search-icon">
            <img src="@/assets/images/searchTwo.png" @click="search" />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="nav-top">
      <nav-bar
        :menuList="menuList"
        style="margin-top: 30px"
        @on-menu="handleMenuClick"
      />
    </div>

    <router-view />
    <div class="menu-wrap page-footer">
      <div class="home-container">
        <el-row :gutter="20">
          <el-col :span="18" :xs="14">
            <nav-bar
              class="footer-nav"
              :menuList="menuList"
              style="margin-top: 5px"
              @on-menu="handleMenuClick"
            />
            <div class="footer-content">
              <p>单位地址：江苏省南京市北京西路28号</p>
              <p>联系电话：025-83591353</p>
              <p>电子邮箱：jslaf2007@163.com</p>
              <p>邮政编码：210024</p>
            </div>
          </el-col>
          <el-col :span="6" :xs="10">
            <img
              src="@/assets/images/erweima.jpg"
              alt=""
              class="qs-code-image"
            />
            <p class="style">关注公众号</p>
          </el-col>
        </el-row>
      </div>
      <div class="footer-tag">
        <!-- <span>苏ICP备16046641号 </span> -->
        <a href="https://beian.miit.gov.cn" target="_blank"
          >苏ICP备16046641号
        </a>
        <span>江苏省法律援助基金会 保留所有权利</span>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import navBar from "@/components/navBar";
import smallNavBar from "@/components/smallNavBar";

export default {
  data() {
    return {
      queryForm:{
        lafNewsTitle:""
      },
      props: {
        expandTrigger: "hover",
        label: "naviName",
        value: "naviPath",
        children: "childrens",
      },
      options: [],
      // 导航栏
      activeIndex: "",
      // 导航下拉列表
      menuList: [],
    };
  },
  computed: {},
  mounted() {
    this.getMenuList();
  },
  methods: {
  search () {
    if(this.queryForm.lafNewsTitle == ''){
      this.$message.warning('请输入搜索内容')
    }
    if (this.queryForm.lafNewsTitle) {
      this.$router.push({
        name: "search",
        query: { keywords: this.queryForm.lafNewsTitle }
      })
    }
    },
    // 首页导航 接口
    getMenuList() {
      this.$axios({
        method: "get",
        url: "/laf/laf/lafNavigation/treeB.ajax",
        params: {
          pageNo: "-1",
          pageSize: "10",
          condition: "-1",
        },
      }).then((response) => {
        let m = JSON.parse(JSON.stringify(response));
        for (let i = 0; i < m.length; i++) {
          if (!m[i].childrens.length) {
            delete m[i].childrens;
          }
        }
        this.options = m;
        this.menuList = response;
        sessionStorage.setItem("menuList", JSON.stringify(response));
        this.activeIndex = this.menuList[0].naviId + this.menuList[0].naviNo;
      }).catch(function (error) {
        console.log(error);
      });
    },
    handleChange(e) {
      // console.log(e)
      if (e.length == 1) {
        this.$router.push(e[0]);
      } else if (e.length == 2) {
        this.$router.push(e[1]);
      }
    },
    handleMenuClick(menu) {
      console.log(menu.naviPath);
      this.$router.push(menu.naviPath);
    },
  },
  components: {
    pageHeader,
    navBar,
    smallNavBar,
  },
  beforeDestroy() {
    sessionStorage.removeItem("menuList");
  },
};
</script>
<style lang="scss">
//  ::v-deep .input .el-input .el-input--medium{
//   height:48px;
// }
@include apply-small {
  .nav-top {
    display: none;
  }
}

.small-nav-bar {
  display: none;
  @include apply-small {
    display: flex;
  }
}
.search-wrap {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 0 10px;
  box-sizing: border-box;
  @include apply-small {
    margin-top: 0;
  }
  .login {
    margin-left: 45px;
    width: 450px;
    margin-top: 6px;
    @include apply-small {
      display: none;
    }
  }
  .el-input__inner {
    outline: none;
    height: 50px;
    background-color: #eff3f5;
    border: none;
  }
  .el-input-group__append {
    width: 100px;
    background-color: $red;
    border: 1px solid $red;
    cursor: pointer;
    text-align: center;
  }

  .search-icon {
    margin-top: 9px;
    height: 35px;
    width: 35px;
  }
}
// 页面底部
.page-footer {
  width: 100%;
  height: 420px;
  background-color: #000000;
  background-image: url(./assets/images/bgc.jpg);
  background-size: 100% 100%;
  padding-top: 52px;
  ::v-deep .el-menu {
    background-color: transparent;
  }
  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #333333;
  }
  ::v-deep .el-menu--horizontal > .el-menu-item {
    color: #333333;
  }

  .footer-content {
    margin: 20px 0 40px;
    p {
      margin-bottom: 15px;
      font-size: 16px;
      color: #333333;
    }
  }
  .footer-tag {
    font-size: 14px;
    text-align: center;
    color: #333333;
    margin-top: 50px;
    // padding-bottom: 20px;
  }
  .footer-tag a {
    margin-right: 20px;
    color: #333;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .qs-code-image {
    width: 240px;
    height: 240px;
    @include apply-small {
      width: 200px;
      height: 200px;
    }
  }
  .style {
     width: 240px;
    text-align: center;
    color: #333333;
    margin-top: 17px;
  }
}

// 分页
.pagination {
  text-align: center;
  margin-top: 40px;
  &.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $red;
  }
}
.footer-nav.menu-wrapper {
  position: relative;
  width: 810px;
  margin-left: 0;
  margin-right: 0;
  .nav-menu {
    flex: none;
    margin-right: 40px;
    .menu-title {
      font-size: 16px;
    }
    .menu-list {
      position: absolute;
      min-width: 120px;
      left: -30px;
    }
    // .menu-item {
    //   font-size: 12px;
    // }
  }
}
.login-search {
  position: relative;

  .search-icon {
    position: absolute;
    width: 90px;
    height: 50px;
    text-align: center;
    border-radius: 5px;
    background-color: #dc2725;
    right: 0px;
    top: -10px;
    cursor: pointer;
    @include apply-small {
      right: 20px;
    }
    img {
      height: 40px;
      margin-top: 5px;
    }
  }
}
</style>